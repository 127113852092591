import {evidenceOptions, numberOptions, confidenceOptions, tonnageOptions, booleanOptions} from "./SelectOptions";
import {IField} from "../types";

export const fields: Array<IField> = [
    // {
    //     name: 'id',
    //     label: 'ID',
    //     category: 'Identifiers',
    //     sub_category: 'Identifiers',
    //     defaultValue: '',
    //     show_if_true: false,
    //     is_multilines: false,
    //     is_evidence_translate: false,
    //     rules: {},
    //     type: 'input',
    //     attrs: {disabled: true},
    //     options: [],
    //     desc: '',
    // },
    {
        name: 'casrn',
        label: 'CASRN',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {required: "This field is required"},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Chemical Abstract Service (CAS) Registry Number',
    },
    {
        name: 'name',
        label: 'Name',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {required: "This field is required"},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Common or IUPAC name of chemical"',
    },
    {
        name: 'ec',
        label: 'EC#',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'European Community Number -- used for chemical substances on the EU market',
    },
    {
        name: 'inchi_key',
        label: 'InChI Key',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Structure-based chemical identifier',
    },
    {
        name: 'inchi_string',
        label: 'InChI',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Computer-generated 27-character identification from InChI code',
    },
    {
        name: 'smiles',
        label: 'SMILES',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Simplified molecular-input line-entry system (structural identifier)',
    },
    {
        name: 'molecular_formula',
        label: 'Molecular Formula',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Molecular Formula',
    },
    {
        name: 'molecular_mass_isotope',
        label: 'Molecular_Mass_Isotope',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Monoisotopic mass from accurate masses of the most abundant natural isotopes in g/mol',
    },
    {
        name: 'alternative_casrn_scifinder',
        label: 'Alternate CASRNs',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Alternate CASRN (used in parallel to the active one)',
    },
    {
        name: 'deleted_casrn_scifinder',
        label: 'Deleted CASRNs',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: true,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Deleted CASRNs (replaced by the active one)',
    },
    {
        name: 'verified_in_commerce',
        label: 'Verified in Commerce',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: '',
    },
    {
        name: 'chemical_structure',
        label: 'Chemical Structure',
        category: 'Identifiers',
        sub_category: 'Identifiers',
        defaultValue: '',
        show_if_true: false,
        is_multilines: false,
        rules: {},
        type: 'image',
        attrs: {},
        options: [],
        desc: '',
    },
    {
        name: 'uvcb',
        label: 'UVCB',
        category: 'Substance Type',
        sub_category: 'Substance Type',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance was categorized as a substance of unknown or variable composition, complex reaction product, or biological materials (UVCB); includes simple mixtures or polymers',
    },
    {
        name: 'organic',
        label: 'Organic',
        category: 'Substance Type',
        sub_category: 'Substance Type',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance contains at least one organic moiety',
    },
    {
        name: 'contains_metal',
        label: 'Contains Metal',
        category: 'Substance Type',
        sub_category: 'Substance Type',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance contains at least one metal or metalloid element',
    },
    {
        name: 'contains_silicon',
        label: 'Contains Silicon',
        category: 'Substance Type',
        sub_category: 'Substance Type',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance contains silicon',
    },
    {
        name: 'contains_phosphorous',
        label: 'Contains Phosphorous',
        category: 'Substance Type',
        sub_category: 'Substance Type',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance contains phosphorous',
    },
    {
        name: 'contains_sulfur',
        label: 'Contains Sulfur',
        category: 'Substance Type',
        sub_category: 'Substance Type',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance contains sulfur',
    },
    {
        name: 'contains_halogen',
        label: 'Contains Halogen',
        category: 'Substance Type',
        sub_category: 'Substance Type',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance contains at least one halogen',
    },
    {
        name: 'confidence_additives',
        label: 'Confidence Chemical is Currently Used in Plastic Additives',
        category: 'Confidence of Use',
        sub_category: 'Confidence of Use',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_uppercase: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: confidenceOptions,
        desc: '',
    },
    {
        name: 'confidence_plastic',
        label: 'Confidence Chemical is Currently Used in Plastics',
        category: 'Confidence of Use',
        sub_category: 'Confidence of Use',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_uppercase: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: confidenceOptions,
        desc: '',
    },
    {
        name: 'confidence_plastic',
        label: 'Confidence Chemical is Currently Used in Commerce',
        category: 'Confidence of Use',
        sub_category: 'Confidence of Use',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_uppercase: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: confidenceOptions,
        desc: '',
    },
    {
        name: 'cmr',
        label: 'CMR: carcinogen, mutagen, or reproductive toxicant',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is a carcinogen, mutagen, or reproductive toxicant according to Regulation EC No. 1272/2008',
    },
    {
        name: 'carcinogenic_c',
        label: 'Carcinogenic',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is a carcinogen. High confidence (1.0) for GHS hazard code H350 (category1, 1A, or 1B); medium confidence (0.5) for GHS hazard code H351 (category 2)',
    },
    {
        name: 'mutagenic_m',
        label: 'Mutagenic',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is germ cell mutagenic. High confidence (1.0) for GHS hazard code H340 (category 1, 1A, or 1B); medium confidence (0.5) for GHS hazard code H341 (category 2)',
    },
    {
        name: 'reprotoxic_r',
        label: 'Reprotoxic',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is a reproductive toxicant. High confidence (1.0) for GHS hazard code H360 or H362 (category 1, 1A, or 1B); medium confidence (0.5) for GHS hazard code H361 (category 2)',
    },
    {
        name: 'specific_target_organ_toxicity_repeated_exposure_stot_re',
        label: 'STOT RE',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that specific target organ toxicity after repeated exposure (STOT RE) comprises all significant health effects, not otherwise specifically included in the GHS, that can impair function in the target organ. High confidence (1.0) for GHS hazard code H372 (category 1); medium confidence (0.5) for GHS hazard code H373 (category 2)',
    },
    {
        name: 'aquatic_toxicity_aqtox',
        label: 'Aquatic Toxicity',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence in substance chronic aquatic toxicity (NOEC or EC10 less than 0.01 mg/L); substance causes adverse effects to aquatic organisms during exposures that are long in relation to the lifecycle of the organism. High confidence (1.0) for GHS hazard code H400 (category 1); medium confidence (0.5) for GHS hazard code H401 (category 2) or H402 (category 3)',
    },
    {
        name: 'respiratory_sensitization_respsens',
        label: 'Respiratory Sensitizer',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance causes respiratory sensitization; substance that induces hypersensitivity of the airways following inhalation of the substance. High confidence (1.0) for GHS hazard code H334 (category 1, 1A, or 1B)',
    },
    {
        name: 'endocrine_disrupting_edc',
        label: 'Endocrine Mode of Action',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is an endocrine disrupting chemical according to Regulations EU No. 528/20121 and EC No. 1107/2009. High confidence (1.0) for substance on REACH EDC list, medium confidence (0.5) for substance listing under assessment or postponed',
    },
    {
        name: 'pbt',
        label: 'PBT: persistent, bioaccumulative and toxic',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is persistent, bioaccumulative and toxic',
    },
    {
        name: 'vpvb',
        label: 'vPvB: very persistent and very bioaccumulative',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is very persistent and very bioaccumulative',
    },
    {
        name: 'perisistent_P',
        label: 'P: persistent',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is persistent; degradation half-life is larger than 60 days in marine water, 40 days in fresh or estuarine water, 180 days in marine sediment, or 120 days in fresh water sediment or soil',
    },
    {
        name: 'very_persistent_vp',
        label: 'vP: very persistent',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is very persistent; degradation half-life is larger than 60 days in marine, fresh, or estuarine water or 180 days in marine sediment, fresh water sediment, or soil',
    },
    {
        name: 'bioaccumulative_b',
        label: 'B: bioaccumulative',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is bioaccumulative; bioconcentration factor (BCF) in aquatic species is larger than 2,000',
    },
    {
        name: 'very_bioaccumulative_vb',
        label: 'vB: very bioaccumulative',
        category: 'Confidence in Hazard Classification',
        sub_category: 'Hazard Properties',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: true,
        rules: {},
        type: 'select',
        attrs: {},
        options: evidenceOptions,
        desc: 'Confidence that substance is very bioaccumulative; bioconcentration factor (BCF) in aquatic species is larger than 5,000',
    },
    {
        name: 'montreal',
        label: 'Listed under Montreal Protocol',
        category: 'Regulation',
        sub_category: 'Regulated by Montreal Protocol',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the Montreal Protocol of the United Nations',
    },
    {
        name: 'us_ozone_depleting_substances',
        label: 'US Ozone Depleting Substance',
        category: 'Regulation',
        sub_category: 'Regulated by Montreal Protocol',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the US implementation of the Montreal Protocol (i.e., the Ozone Depleting Substances List under the Clean Air Act)',
    },
    {
        name: 'eu_controlled_substances_montreal',
        label: 'EU Ozone Depleting Substance',
        category: 'Regulation',
        sub_category: 'Regulated by Montreal Protocol',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the EU implementation of the Montreal Protocol (i.e., the EU Regulations on substances that deplete the ozone layer)',
    },
    {
        name: 'stockholm',
        label: 'Listed under Stockholm Convention on POPs',
        category: 'Regulation',
        sub_category: 'Regulated by Stockholm Convention on Persistent Organic Pollutants',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the Stockholm Convention on Persistent Organic Pollutants of the United Nations (as provided by the Japanese Chemical Risk Information Platform)',
    },
    {
        name: 'eu_pop_directive',
        label: 'EU POP',
        category: 'Regulation',
        sub_category: 'Regulated by Stockholm Convention on Persistent Organic Pollutants',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the EU implementation of the Stockholm Convention (i.e., the EU Persistent Organic Pollutant regulation)',
    },
    {
        name: 'rotterdam',
        label: 'Listed under Rotterdam Convention',
        category: 'Regulation',
        sub_category: 'Regulated by Rotterdam Convention',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the Rotterdam Convention (as provided by the Japanese Chemical Risk Information Platform)',
    },
    {
        name: 'eu_priority_informed_concent',
        label: 'EU Prior Informed Consent Regulation List',
        category: 'Regulation',
        sub_category: 'Regulated by Rotterdam Convention',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the EU implementation of the Rotterdam Convention (i.e., the EU Prior Informed Consent Regulation List)',
    },
    {
        name: 'reach_restriction',
        label: 'EU REACH Restriction List',
        category: 'Regulation',
        sub_category: 'Regulated by EU REACH',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the EU REACH restriction list',
    },
    {
        name: 'reach_authorisation',
        label: 'EU REACH Authorization List',
        category: 'Regulation',
        sub_category: 'Regulated by EU REACH',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the EU REACH authorization list',
    },
    {
        name: 'reach_svhc',
        label: 'EU REACH Substances of Very High Concern (SVHC) List',
        category: 'Regulation',
        sub_category: 'Regulated by EU REACH',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the EU REACH substances of very high concern list',
    },
    {
        name: 'rohs_directive',
        label: 'Listed under EU Restriction of Hazardous Substances (RoHS)',
        category: 'Regulation',
        sub_category: 'Regulated by Other EU Directives',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the EU Restriction of Hazardous Substances (RoHS) directive',
    },
    {
        name: 'toys_directive',
        label: 'Listed under EU Toy Safety Directive',
        category: 'Regulation',
        sub_category: 'Regulated by Other EU Directives',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the EU Toy Safety directive',
    },
    {
        name: 'california_proposition65',
        label: 'Listed under California Proposition 65',
        category: 'Regulation',
        sub_category: 'Regulated in USA',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under California proposition 65',
    },
    {
        name: 'cscl_classi_restriction',
        label: 'CSCL Class I Restriction',
        category: 'Regulation',
        sub_category: 'Regulated in Japan (Chemical Substances Control Law)',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the Japanese CSCL Class I Substance List, which is similar to a restriction list',
    },
    {
        name: 'cscl_classii_authorization',
        label: 'CSCL Class II Restriction',
        category: 'Regulation',
        sub_category: 'Regulated in Japan (Chemical Substances Control Law)',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the Japanses CSCL Class II Substance list, which is similar to an authorization list',
    },
    {
        name: 'isha_restriction',
        label: 'ISHA Prohibited Substance',
        category: 'Regulation',
        sub_category: 'Regulated in Japan (Industrial Safety and Health Act)',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the Japanese ISHA Prohibited Substances list, which is similar to a restriction list',
    },
    {
        name: 'isha_authorisation',
        label: 'ISHA Substance Requiring Permission',
        category: 'Regulation',
        sub_category: 'Regulated in Japan (Industrial Safety and Health Act)',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the Japanese ISHA Substances Requiring Permission list, which is similar to an authorisation list',
    },
    {
        name: 'kreach_restriction',
        label: 'K-REACH Prohibited Substance',
        category: 'Regulation',
        sub_category: 'Regulated in South Korea (K-REACH)',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under South Korean REACH Prohibited Substances List, which is similar to a restriction list',
    },
    {
        name: 'kreach_authorisation',
        label: 'K-REACH Restricted Substance',
        category: 'Regulation',
        sub_category: 'Regulated in South Korea (K-REACH)',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under South Korean REACH Restricted Substances List, which is similar to an authorization list',
    },
    {
        name: 'food_contact_positive_list',
        label: 'Food Contact Positive List',
        category: 'Regulation',
        sub_category: 'Regulated for Food Contact',
        defaultValue: '',
        show_if_true: false,
        is_multilines: true,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'The substance appears under a positive regulatory list for food contact plastics',
    },
    {
        name: 'relevant_food_contact_positive_lists',
        label: 'Relevant Food Contact Positive Lists',
        category: 'Regulation',
        sub_category: 'Regulated for Food Contact',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'Postive regulatory lists for food-contact plastics under which this substance is listed. Different regulations are comma-seperated',
    },
    {
        name: 'eu_food_contact_materials_list',
        label: 'Listed under EU Regulation on Plastic Food Contact Materials',
        category: 'Regulation',
        sub_category: 'Regulated for Food Contact',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the positive list in the EU Regulation on plastic food contact materials',
    },
    {
        name: 'usa_generally_recognized_as_safe',
        label: 'Listed under US EPA Generally Recognized as Safe (GRAS) or Everything Added to Food (EAFUS)',
        category: 'Regulation',
        sub_category: 'Regulated for Food Contact',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the US EPA Generally Recognized as Safe (GRAS) or the Everything added to food (EAFUS) list',
    },
    {
        name: 'japan_food_contact_materials_list',
        label: 'Listed under Japan Food Sanitation Act',
        category: 'Regulation',
        sub_category: 'Regulated for Food Contact',
        defaultValue: null,
        show_if_true: true,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'The substance is listed under the positive list in the Japanese Food Sanitation Act',
    },
    {
        name: 'function',
        label: 'Function',
        category: 'Use',
        sub_category: 'Overview',
        defaultValue: '',
        show_if_true: false,
        is_multilines: true,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Functions that this substance might fulfill',
    },
    {
        name: 'polymer',
        label: 'Polymer Types',
        category: 'Use',
        sub_category: 'Overview',
        defaultValue: '',
        show_if_true: false,
        is_multilines: true,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Polymers that this substance might be compatible with',
    },
    {
        name: 'industrial_sector',
        label: 'Industrial Sector',
        category: 'Use',
        sub_category: 'Overview',
        defaultValue: '',
        show_if_true: false,
        is_multilines: true,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Industrial sectors in which plastics including this substance might be used',
    },
    {
        name: 'industrial_sector_food_contact',
        label: 'Industrial Sector Food Contact',
        category: 'Use',
        sub_category: 'Overview',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'boolean',
        attrs: {},
        options: booleanOptions,
        desc: 'Regions in which this substance is registered',
    },
    {
        name: 'number_regions',
        label: 'Regions',
        category: 'Use',
        sub_category: 'Overview',
        defaultValue: null,
        show_if_true: false,
        is_multilines: false,
        is_evidence_translate: false,
        rules: {},
        type: 'select',
        attrs: {},
        options: numberOptions,
        desc: 'Number of considered regions in which this substance is registered',
    },
    {
        name: 'regions',
        label: 'Regions where Substance is Registered',
        category: 'Use',
        sub_category: 'Overview',
        defaultValue: '',
        show_if_true: false,
        is_multilines: true,
        is_evidence_translate: false,
        rules: {},
        type: 'input',
        attrs: {},
        options: [],
        desc: 'Regions in which this substance is registered',
    },
    // {
    //     name: 'eu',
    //     label: 'EU',
    //     category: 'Use',
    //     sub_category: 'Metric tons per year',
    //     defaultValue: null,
    //     show_if_true: false,
    //     is_multilines: false,
    //     is_evidence_translate: false,
    //     rules: {},
    //     type: 'select',
    //     attrs: {},
    //     options: tonnageOptions,
    //     desc: 'Minimal tonnage in the EU according to tonnage band in metric tons per year (t/yr). Retrieved from the ECHA registered tonnage (id = 187 on Sheet S2)',
    // },
    // {
    //     name: 'nordic_countries_spin',
    //     label: 'Nordic Countries',
    //     category: 'Use',
    //     sub_category: 'Metric tons per year',
    //     defaultValue: null,
    //     show_if_true: false,
    //     is_multilines: false,
    //     is_evidence_translate: false,
    //     rules: {},
    //     type: 'select',
    //     attrs: {},
    //     options: tonnageOptions,
    //     desc: 'Total tonnage in the Nordic countries in metric tons per year (t/yr); retrieved from the SPIN database',
    // },
    // {
    //     name: 'usa',
    //     label: 'USA',
    //     category: 'Use',
    //     sub_category: 'Metric tons per year',
    //     defaultValue: null,
    //     show_if_true: false,
    //     is_multilines: false,
    //     is_evidence_translate: false,
    //     rules: {},
    //     type: 'select',
    //     attrs: {},
    //     options: tonnageOptions,
    //     desc: 'Total tonnage in the USA in metric tons per year (t/yr); retrieved from the US CDR database',
    // },
    // {
    //     name: 'oecd',
    //     label: 'OECD',
    //     category: 'Use',
    //     sub_category: 'Metric tons per year',
    //     defaultValue: null,
    //     show_if_true: false,
    //     is_multilines: false,
    //     is_evidence_translate: false,
    //     rules: {},
    //     type: 'select',
    //     attrs: {},
    //     options: tonnageOptions,
    //     desc: 'Minimal tonnage for substances part of the OECD high production volume chemicals list in metric tons per year (t/yr). Substances are only listed if their tonnage exceeds 1,000 t/yr. Retrieved from OECD high production volume chemicals list',
    // },
    // {
    //     name: 'total',
    //     label: 'Total',
    //     category: 'Use',
    //     sub_category: 'Metric tons per year',
    //     defaultValue: null,
    //     show_if_true: false,
    //     is_multilines: false,
    //     is_evidence_translate: false,
    //     rules: {},
    //     type: 'select',
    //     attrs: {},
    //     options: tonnageOptions,
    //     desc: 'Total tonnage in all considered regions in metric tons per year (t/yr). US and EU tonnages were combined (SPIN tonnages were used when EU was not available). OECD tonnages were used if no other tonnages were available',
    // }
]
