import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import {showSuccessAlert} from "../alert/alertSlice";

export const fetchHistory = createAsyncThunk('import/history', async () => {
    const response = await api.get(`import/history`);
    return response.data;
});

export const fetchUpload = createAsyncThunk('import/upload', async (file: any, thunkAPI) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await api.post('import/upload', formData, {headers: {"Content-Type": "multipart/form-data"}});
    thunkAPI.dispatch(showSuccessAlert({message: response.data.message}));
    return response.data;
});

interface ImportState {
    history: any;
    isLoading: boolean;
}

const initialState: ImportState = {
    history: [],
    isLoading: false,
};

export const importSlice = createSlice({
    name: 'imports',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchHistory.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchHistory.fulfilled, (state: any, action: any) => {
                state.history = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchHistory.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpload.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpload.fulfilled, (state: any, action: any) => {
                state.history = action.payload.data.resource.history;
                state.isLoading = false;
            })
            .addCase(fetchUpload.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase((state: any) => {
                state.isLoading = false;
            });
    },
});

export default importSlice.reducer;
