import React, {FC, useMemo} from 'react';
import {DashboardWrapper, IconsWrapper} from "./styled";
import {IChemical, IForm} from "types";
import Icons from "./Icons/Icons";
import Regions from "./Regions/Regions";
import DataSources from "./DataSources/DataSources";
import OptionsLegend from "./OptionsLegend/OptionsLegend";

type Props = {
    chemical: IChemical | null
}

const Overview: FC<Props> = ({chemical}) => {
    const regions = useMemo(() => {
        return !!chemical?.regions && chemical?.regions.split(', ') || [];
    }, [chemical])

    return (
        <DashboardWrapper>
            <DataSources chemical={chemical}/>
            <IconsWrapper>
                <Icons type={'function'} items={chemical?.functions ?? []} flex={3} grow={3.15}/>
                <Icons type={'is'} items={chemical?.industrial_sectors ?? []} flex={2} grow={2}/>
                <Icons type={'polymer'} items={chemical?.polymers ?? []} flex={2} grow={2}/>
            </IconsWrapper>
            <OptionsLegend/>
            <Regions regions={regions}/>
        </DashboardWrapper>
    )
};

export default Overview;
