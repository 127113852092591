import React, {FC} from 'react';
import {IChemical} from "types";
import {ListTableRow, Uppercase, YesNoValue} from "components/pages/ChemicalDetails/parts/Tabs/Common";
import {FunctionsPolymersUsesRegions} from "components/pages/ChemicalDetails/parts/Tabs/Use/Shared";
import FieldDescriptions from "constants/FieldDescriptions";
import {
    DefaultTable,
    Desc,
    GroupHeader,
    Label,
    MergedTitleCell,
    MergedValueCell,
    OverviewUseTable,
    WideTableHeader
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    chemical: IChemical | null;
    isDescShown: boolean;
}

const UseList: FC<Props> = ({chemical, isDescShown}) => {
    const getColor = (value: any) => {
        let color = '#000';
        if (value === 'low') color = '#1a1aff';
        else if (value === 'medium') color = '#fc642a';
        else if (value === 'high') color = '#ff0f0f';
        return color;
    }

    return (
        <div>
            <GroupHeader>Used in Commerce</GroupHeader>
            <DefaultTable>
                <ListTableRow
                    label="Confidence Chemical is Currently Used in Plastic Additives"
                    value={chemical?.confidence_additives}
                    Component={Uppercase}
                    desc={FieldDescriptions.confidence_additives}
                    isDescShown={isDescShown}
                    color={getColor(chemical?.confidence_additives)}
                />
                {/*<ListTableRow*/}
                {/*    label="Confidence Chemical is Currently Used in Plastics"*/}
                {/*    value={chemical?.confidence_plastic}*/}
                {/*    Component={Uppercase}*/}
                {/*    desc={FieldDescriptions.confidence_plastic}*/}
                {/*    isDescShown={isDescShown}*/}
                {/*    color={getColor(chemical?.confidence_plastic)}*/}
                {/*/>*/}
                <ListTableRow
                    label="Confidence Chemical is Currently Used in Commerce as a Plastic Additive"
                    value={chemical?.confidence_commerce}
                    Component={Uppercase}
                    desc={FieldDescriptions.confidence_commerce}
                    isDescShown={isDescShown}
                    color={getColor(chemical?.confidence_commerce)}
                />
            </DefaultTable>

            <GroupHeader>Overview of Use</GroupHeader>
            <OverviewUseTable>
                <div/>
                <WideTableHeader>Industry Confirmed</WideTableHeader>
                <WideTableHeader>UNEP Identified</WideTableHeader>

                <FunctionsPolymersUsesRegions
                    chemical={chemical}
                    isDescShown={isDescShown}
                />

                <MergedTitleCell>
                    <Label>Regions</Label>
                    {isDescShown && <Desc>Number of considered regions in which this substance is registered</Desc>}
                </MergedTitleCell>
                <MergedValueCell>
                    {chemical?.number_regions ?? ''}
                </MergedValueCell>

                <MergedTitleCell>
                    <Label>Industrial Sector Food Contact</Label>
                    {isDescShown && <Desc></Desc>}
                </MergedTitleCell>
                <MergedValueCell>
                    <YesNoValue value={chemical?.industrial_sector_food_contact}/>
                </MergedValueCell>

            </OverviewUseTable>
        </div>
    );
};

export default UseList;
