export const isEmptyObject = (obj: object | null) => {
  if (!obj) return true;
  return !Object.values(obj).length;
};

export const generateHash = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const cutString = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
};

export const onlyUnique = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index;
};

export const capitalizeFirstLetter = (value: string) => {
  if (value && value.length) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else return value;
}

export const endEllipsis = (value = '', limit = 10) => {
  return value.slice(0, limit) + (value.length > limit ? "..." : "");
}

export const valueFormat = (value: string | number | undefined | null) => {
  if (value === null || value === undefined) {
    return '';
  }

  if (typeof value === 'string') {
    value = parseFloat(value);
    if (isNaN(value)) return '';
  }

  if (value.toString().includes('e')) {
    return value.toFixed(20).replace(/\.?0+$/, '');
  }

  return value.toString();
}