import React, {FC} from 'react';
import {Controller} from "react-hook-form";
import {Control} from "react-hook-form/dist/types";
import {FieldError} from "react-hook-form";
import {IFormField} from "types";
import {Radios, StyledRadio} from "./styled";

type Props = {
    item: IFormField,
    control: Control<any>
    error: FieldError;
}

const FormRadio: FC<Props> = ({item: {defaultValue = '', ...item}, control, error}) => {
    const {name, rules, options = []} = item;
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({field: {ref, ...field}}) => (
                <Radios
                    row={true}
                    {...field}
                >
                    {!!options.length && options.filter(item => (item.value !== null && item.value !== '')).map(({label, value}) => (
                        <StyledRadio
                            key={`${name}_${label}_${value}`}
                            label={label}
                            value={value}
                        />
                    ))}
                </Radios>
            )}
        />
    );
};

export default FormRadio;
