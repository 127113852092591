import styled from "styled-components";
import {ReactComponent as DeleteImg} from "assets/images/icons/trash.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 100% - 10px;
`;

export const DeleteBtn = styled(DeleteImg)`
  position: absolute;
  display: none;
  width: 10px;
  height: 10px;
  fill: ${(props) => props.theme.terracottaColor};
  top: 6px;
  right: 6px;

  &:hover {
    transform: scale(1.1);  
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  object-fit: contain;
  cursor: pointer;
  transition: 0.3s;
  z-index: 999;
  &:hover {
    transform: scale(2.5);
    border: 1px solid lightgray;

    ${DeleteBtn} {
      display: flex;
    }
  }
`;

export const Image = styled.img`
  height: 100px;
  border-radius: 2px;
`;

export const ImgButtons = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 10px;
`;
