import React, {FC, useMemo} from 'react';
import {GroupHeader} from "components/pages/ChemicalDetails/parts/Tabs/styled";
import {IClassification} from "types";
import {Box} from "@mui/material";
import {Jurisdiction, Table2Col, Table3Col, TableHeaderValue, Value, Value3ColTable, ValueBold} from "./styled";

type Props = {
    classifications: Array<IClassification>;
}

type GroupedClassification = {
    "casrn": string;
    "ghs": string;
    "jurisdiction": string;
    "signal": string;
    "symbol": string;
    "codes": Array<{
        "code": string;
        "class": string;
        "statement": string;
    }>
}

export const ClassificationLabelling: FC<Props> = ({classifications}) => {
    const groupedClassifications: Array<GroupedClassification> = useMemo(() => {
        return classifications.reduce((acc: Array<GroupedClassification>, item) => {
                const existing = acc.find((group: any) =>
                    group.casrn === item.casrn &&
                    group.ghs === item.ghs &&
                    group.jurisdiction === item.jurisdiction &&
                    group.signal === item.signal &&
                    group.symbol === item.symbol
                );
                if (existing) {
                    existing.codes.push({
                        code: item.code,
                        class: item.class,
                        statement: item.statement
                    });
                } else {
                    acc.push({
                        casrn: item.casrn,
                        ghs: item.ghs,
                        jurisdiction: item.jurisdiction,
                        signal: item.signal,
                        symbol: item.symbol,
                        codes: [{
                            code: item.code,
                            class: item.class,
                            statement: item.statement
                        }]
                    });
                }

                return acc;
            }, []);
    }, [classifications]);

    return (
        <>
            <GroupHeader>Globally Harmonized System of Classification & Labelling of Chemicals (GHS)</GroupHeader>
            {!!groupedClassifications.length && groupedClassifications.map((item: GroupedClassification) => (
                <Box key={item.jurisdiction} display="flex" flexDirection="column" gap="10px">
                    <Jurisdiction>{item.jurisdiction}</Jurisdiction>
                    <Table2Col>
                        <ValueBold>GHS:</ValueBold>
                        <Value style={{color: '#0099ca'}}>{item.ghs}</Value>
                        <ValueBold>Signal:</ValueBold>
                        <Value>{item.signal}</Value>
                        <ValueBold>GHS Symbol:</ValueBold>
                        <Value>{item.symbol}</Value>
                    </Table2Col>
                    <Table3Col>
                        <TableHeaderValue>Code</TableHeaderValue>
                        <TableHeaderValue>Class</TableHeaderValue>
                        <TableHeaderValue>Statement</TableHeaderValue>
                        {!!item.codes.length && item.codes.map((row) => (
                            <>
                                <Value3ColTable>{row.code}</Value3ColTable>
                                <Value3ColTable>{row.class}</Value3ColTable>
                                <Value3ColTable>{row.statement}</Value3ColTable>
                            </>
                        ))}
                    </Table3Col>
                </Box>
            ))}
        </>
    );
};
