export const tabs: Array<any> = [
    {
        alias: 'overview',
        title: 'Overview',
        items: []
    },
    {
        alias: 'use',
        title: 'Use',
        items: [
            'Identifiers',
            'Substance Type',
            'Confidence of Use',
            'Overview',
            'Metric tons per year',
        ]
    },
    {
        alias: 'hazard',
        title: 'Hazard',
        items: [
            'Hazard Properties'
        ]
    },
    {
        alias: 'regulation',
        title: 'Regulation',
        items: [
            'Regulated by Montreal Protocol',
            'Regulated by Stockholm Convention on Persistent Organic Pollutants',
            'Regulated by Rotterdam Convention',
            'Regulated by EU REACH',
            'Regulated by Other EU Directives',
            'Regulated in USA',
            'Regulated in Japan (Chemical Substances Control Law)',
            'Regulated in Japan (Industrial Safety and Health Act)',
            'Regulated in South Korea (K-REACH)',
            'Regulated for Food Contact',
        ]
    },
    {
        alias: 'risk',
        title: 'Risk',
        items: ['Threshold Values']
    }
]
