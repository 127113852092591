import React, {FC} from 'react';
import {Button} from "components/controls";
import {ButtonsWrapper} from "./styled";

type Props = {
    isNewUser: boolean,
    isActive: boolean,
    isCurrentUser: boolean,
    handleSave: () => void,
    handleBack: () => void;
    handleArchive: (active: boolean) => void,
}

const Buttons: FC<Props> = ({isNewUser,  isActive, isCurrentUser, handleSave, handleBack, handleArchive}) => {
    return(
       <ButtonsWrapper>
           <>
               {/*{!isNewUser && !isCurrentUser && (*/}
               {/*    isActive*/}
               {/*        ? <Button title={'Archive User'} color={'terracotta-transparent'} size='MD2' icon='archive' onClick={() => handleArchive(false)}/>*/}
               {/*        : <Button title={'Activate User'} color={'terracotta'} size='MD2' icon='activate' onClick={() => handleArchive(true)}/>*/}
               {/*)}*/}
               <Button title={`${isNewUser ? 'Create' : 'Save'}`} color={'red-transparent'} size='SM' icon='save' onClick={handleSave}/>
               <Button title={'Back'} color={'blue-transparent'} size='SM' icon='back' onClick={handleBack}/>
           </>
       </ButtonsWrapper>
   );
};

export default Buttons;
