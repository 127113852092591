import React, {Dispatch, FC, SetStateAction} from 'react';
import {Badge, StyledAside, StyledButton, StyledToggle, ToggleWrapper} from "./styled";
import {ITab} from "types";
import Verified from 'assets/images/badge_verified.png'
import NotVerified from 'assets/images/badge_not_verified.png'
import {Rollover} from "./styled";

type Props = {
    tab: ITab['alias'],
    tabs: Array<ITab>,
    setTab: Dispatch<SetStateAction<ITab['alias']>>;
    setIsDescShown: Dispatch<React.SetStateAction<boolean>>,
    isVerified?: boolean
}

const Aside: FC<Props> = ({setTab, tabs, tab = 'overview', setIsDescShown, isVerified}) => {
    return (
        <StyledAside>
            <>
                {tabs.map((item) => (
                    <StyledButton
                        key={item.alias}
                        title={item.title}
                        variant='aside-black'
                        size='XL'
                        color='transparent'
                        active={tab === item.alias}
                        onClick={() => setTab(item.alias)}
                    />
                ))}
            </>
            <ToggleWrapper>
                <StyledToggle variant={'dark'} label='Show Descriptions' onChange={(e) => setIsDescShown(e.target.checked)}/>
            </ToggleWrapper>
            {isVerified !== undefined && (
                <Badge>
                    <img src={isVerified ? Verified : NotVerified} width={100} alt=''/>
                    <Rollover>Status in commerce as a plastic additive independently verified by ICCA via industry survey and publicly available information such as ECHA REACH, US EPA CDR, Japan Food Sanitation Act</Rollover>
                </Badge>
            )}
        </StyledAside>
    );
};

export default Aside;
