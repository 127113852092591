import React, {FC} from 'react';
import {IChemical} from "types";
import {
    ChemPropertiesTable,
    ComingSoon,
    Desc,
    GroupHeader,
    Label,
    TitleCell,
    ValueCell,
    WideTableHeader
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    chemical: IChemical | null;
    isDescShown: boolean;
}

const HazardList: FC<Props> = ({chemical, isDescShown}) => {
    return (
        <div>
            <GroupHeader>Environmental Fate and Transport (Predicted)</GroupHeader>
            <ChemPropertiesTable>
                <div/>
                <WideTableHeader>Median</WideTableHeader>
                <WideTableHeader>Low</WideTableHeader>
                <WideTableHeader>High</WideTableHeader>
                <WideTableHeader>Unit</WideTableHeader>

                <TitleCell>
                    <Label>Fish biotransformation Half-Life (km)</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.fish_biotransformation_halflife_avg}</ValueCell>
                <ValueCell>{chemical?.fish_biotransformation_halflife_low}</ValueCell>
                <ValueCell>{chemical?.fish_biotransformation_halflife_high}</ValueCell>
                <ValueCell>{chemical?.fish_biotransformation_halflife_unit}</ValueCell>

                <TitleCell>
                    <Label>Bioconcentration Factor</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.bioconcentration_factor_avg}</ValueCell>
                <ValueCell>{chemical?.bioconcentration_factor_low}</ValueCell>
                <ValueCell>{chemical?.bioconcentration_factor_high}</ValueCell>
                <ValueCell>{chemical?.bioconcentration_factor_unit}</ValueCell>

                <TitleCell>
                    <Label>Soil Adsorption Coefficient (Koc)</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.soil_adsorption_coefficient_avg}</ValueCell>
                <ValueCell>{chemical?.soil_adsorption_coefficient_low}</ValueCell>
                <ValueCell>{chemical?.soil_adsorption_coefficient_high}</ValueCell>
                <ValueCell>{chemical?.soil_adsorption_coefficient_unit}</ValueCell>

                <TitleCell>
                    <Label>Ready Biodegradation</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.ready_biodegredation_avg}</ValueCell>
                <ValueCell>{chemical?.ready_biodegredation_low}</ValueCell>
                <ValueCell>{chemical?.ready_biodegredation_high}</ValueCell>
                <ValueCell>{chemical?.ready_biodegredation_unit}</ValueCell>

                <TitleCell>
                    <Label>Atmospheric Hydroxylation Rate</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.atmospheric_hydroxylation_rate_avg}</ValueCell>
                <ValueCell>{chemical?.atmospheric_hydroxylation_rate_low}</ValueCell>
                <ValueCell>{chemical?.atmospheric_hydroxylation_rate_high}</ValueCell>
                <ValueCell>{chemical?.atmospheric_hydroxylation_rate_unit}</ValueCell>

                <TitleCell>
                    <Label>Biodegradation Half-Life</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.biodegregation_halflife_avg}</ValueCell>
                <ValueCell>{chemical?.biodegregation_halflife_low}</ValueCell>
                <ValueCell>{chemical?.biodegregation_halflife_high}</ValueCell>
                <ValueCell>{chemical?.biodegregation_halflife_unit}</ValueCell>

                <TitleCell>
                    <Label>Bioaccumulation Factor</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.bioaccumulation_factor_avg}</ValueCell>
                <ValueCell>{chemical?.bioaccumulation_factor_low}</ValueCell>
                <ValueCell>{chemical?.bioaccumulation_factor_high}</ValueCell>
                <ValueCell>{chemical?.bioaccumulation_factor_unit}</ValueCell>

            </ChemPropertiesTable>

            <GroupHeader>Ecotoxicity</GroupHeader>
            <ComingSoon>Coming Soon!</ComingSoon>

            <GroupHeader>Mammalian Toxicity</GroupHeader>
            <ComingSoon>Coming Soon!</ComingSoon>
        </div>
    );
};

export default HazardList;
