import React, {FC} from 'react';
import {IChemical, IForm} from "types";
import {FunctionsPolymersUsesRegions} from "components/pages/ChemicalDetails/parts/Tabs/Use/Shared";
import {FormControl, FormTableRow} from "components/pages/ChemicalDetails/parts/Tabs/Common";
import {booleanOptions, confidenceOptions, numberOptions} from "constants/SelectOptions";
import FieldDescriptions from "../../../../../../constants/FieldDescriptions";
import {
    DefaultTable,
    Desc,
    GroupHeader,
    Label,
    MergedTitleCell,
    MergedValueCell,
    OverviewUseTable,
    WideTableHeader
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    form: IForm;
    chemical: IChemical | null;
    isDescShown: boolean;
}

const UseForm: FC<Props> = ({form, chemical, isDescShown}) => {
    return (
        <div>
            <GroupHeader>Used in Commerce</GroupHeader>
            <DefaultTable>
                <FormTableRow
                    label="Confidence Chemical is Currently Used in Plastic Additives"
                    form={form}
                    field={{
                        name: 'confidence_additives',
                        type: 'select',
                        options: confidenceOptions,
                    }}
                    desc={FieldDescriptions.confidence_additives}
                    isDescShown={isDescShown}
                />
                {/*<FormTableRow*/}
                {/*    label="Confidence Chemical is Currently Used in Plastics"*/}
                {/*    form={form}*/}
                {/*    field={{*/}
                {/*        name: 'confidence_plastic',*/}
                {/*        type: 'select',*/}
                {/*        options: confidenceOptions,*/}
                {/*    }}*/}
                {/*    desc={FieldDescriptions.confidence_plastic}*/}
                {/*    isDescShown={isDescShown}*/}
                {/*/>*/}
                <FormTableRow
                    label="Confidence Chemical is Currently Used in Commerce as a Plastic Additive"
                    form={form}
                    field={{
                        name: 'confidence_commerce',
                        type: 'select',
                        options: confidenceOptions,
                    }}
                    desc={FieldDescriptions.confidence_commerce}
                    isDescShown={isDescShown}
                />
            </DefaultTable>

            <GroupHeader>Overview of Use</GroupHeader>
            <OverviewUseTable>
                <div/>
                <WideTableHeader>Industry Confirmed</WideTableHeader>
                <WideTableHeader>UNEP Identified</WideTableHeader>

                <FunctionsPolymersUsesRegions
                    chemical={chemical}
                    isDescShown={isDescShown}
                />

                <MergedTitleCell>
                    <Label>Regions</Label>
                    {isDescShown && <Desc>Number of considered regions in which this substance is registered</Desc>}
                </MergedTitleCell>
                <MergedValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'number_regions',
                            type: 'select',
                            options: numberOptions,
                        }}
                    />
                </MergedValueCell>

                <MergedTitleCell>
                    <Label>Industrial Sector Food Contact</Label>
                    {isDescShown && <Desc></Desc>}
                </MergedTitleCell>
                <MergedValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'industrial_sector_food_contact',
                            type: 'boolean',
                            options: booleanOptions,
                        }}
                    />
                </MergedValueCell>

            </OverviewUseTable>
        </div>
    );
};

export default UseForm;
