import React, {FC, ReactNode} from 'react';
import {Box} from "@mui/material";
import {capitalizeFirstLetter} from "utils";
import {IChemicalOption, IForm, IFormField} from "types";
import {FormInput, FormRadio, FormSelect, FormUploader} from "components/pages/ChemicalDetails/parts/сontrols";
import {ReactComponent as ExternalLink} from 'assets/images/icons/external-link.svg';

import {
    Desc,
    Label,
    TitleCell,
    Value,
    ValueCell,
    SubstanceImageWrapper,
    SubstanceImg,
    TwoColumnsValueWrapper,
    TwoColumnsValueCell,
    EvidenceWrapper,
    EvidenceText,
    Rollover
} from "./styled";

type ListTableRowProps = {
    label: string;
    value?: string | number | boolean | null | undefined;
    url?: string;
    Component?: React.FunctionComponent<any>;
    desc?: string;
    showIfTrue?: boolean;
    isDescShown?: boolean;
    color?: string;
}

type FormTableRowProps = {
    form: IForm,
    field: IFormField,
    label: string;
    desc?: string;
    showIfTrue?: boolean;
    isDescShown?: boolean;
}

type FormControlProps = {
    form: IForm,
    field: IFormField,
}

type SubstanceImageProps = {
    value?: ReactNode;
}

type YesNoValueProps = {
    value: string | number | boolean | null | undefined;
    trueOnly?: boolean;
}

type LinkProps = {
    value: string | number | boolean | null | undefined;
    url?: string;
}

type UppercaseProps = {
    value: string | number | boolean | null | undefined;
}

type TwoColumnsValueProps = {
    value: string | null
}

type TwoColumnsSurveyUnepProps = {
    items: Array<IChemicalOption>;
}

type EvidenceProps = {
    value: string | number | boolean | null | undefined;
}

type ClassificationListRowProps = {
    label: string;
    value?: string | number | boolean | null | undefined;
    desc?: string;
    isDescShown?: boolean;
}

export const handleBoolValue = (value: string | number | boolean | null | undefined) => {
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
    } else if (typeof value === 'string') {
        return value;
    } else if (typeof value === 'number') {
        return (value > 0) ? 'Yes' : 'No';
    } else return '';
}

export const SubstanceImage: FC<SubstanceImageProps> = ({value}) => {
    return (
        <SubstanceImageWrapper>
            <SubstanceImg
                src={value as string}
                alt={''}
            />
        </SubstanceImageWrapper>
    );
};

export const YesNoValue: FC<YesNoValueProps> = ({value, trueOnly = false}) => {
    return <>
        {handleBoolValue(value)}
    </>
};

export const Link: FC<LinkProps> = ({value, url = ''}) => {
    return <Box display="flex" gap="8px">
        {value}
        <a href={url} target="_blank" rel="noopener noreferrer"><ExternalLink /></a>
    </Box>
};

export const Uppercase: FC<UppercaseProps> = ({value}) => {
    return <>
        {capitalizeFirstLetter(`${value ? value : ''}`)}
    </>
};

export const ListTableRow: FC<ListTableRowProps> = ({label, value, Component, desc, showIfTrue = false, isDescShown = false, color = 'inherit', url}) => {
    const isShown = (!showIfTrue || (showIfTrue && handleBoolValue(value) === 'Yes'))
    return isShown ? (
        <>
            <TitleCell>
                <Label>{label}</Label>
                {isDescShown && <Desc>{desc}</Desc>}
            </TitleCell>
            <ValueCell style={{color: `${color}`}}>
                {Component
                    ? <Component value={value} url={url} />
                    : <Value>{value}</Value>
                }
            </ValueCell>
        </>
    ) : null;
};

export const FormControl: FC<FormControlProps> = ({form, field}) => {
    const {control, errors} = form;
    return (
        <>
            {field.type === 'input' && <FormInput item={field} control={control} error={errors[field.name]} />}
            {field.type === 'select' && <FormSelect item={field} control={control} error={errors[field.name]} />}
            {field.type === 'boolean' && <FormRadio item={field} control={control} error={errors[field.name]} />}
            {field.type === 'image' && <FormUploader item={field} control={control} />}
        </>
    );
};

export const FormTableRow: FC<FormTableRowProps> = ({label, field, form, desc, showIfTrue = false, isDescShown = false}) => {
    return (
        <>
            <TitleCell isForm>
                <Label>{label}</Label>
                {isDescShown && <Desc>{desc}</Desc>}
            </TitleCell>
            <ValueCell>
                <FormControl
                    form={form}
                    field={field}
                />
            </ValueCell>
        </>
    );
};


export const TwoColumnsValue: FC<TwoColumnsValueProps> = ({value}) => {
    const items = value ? value.split(",").map(item => item.trim()) : [];
    const middleIndex = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, middleIndex);
    const secondHalf = items.slice(middleIndex);

    const prepareValue = (value: string) => {
        return capitalizeFirstLetter(value.replace('&', 'and'));
    }

    return(
        <TwoColumnsValueWrapper>
            <div>
                {!!firstHalf && firstHalf.map((item) => (
                    <TwoColumnsValueCell key={item}>
                        {prepareValue(`${item}`)}
                    </TwoColumnsValueCell>
                ))}
            </div>
            <div>
                {!!secondHalf && secondHalf.map((item) => (
                    <TwoColumnsValueCell key={item}>
                        {prepareValue(`${item}`)}
                    </TwoColumnsValueCell>
                ))}
            </div>
        </TwoColumnsValueWrapper>
    );
};

export const TwoColumnsSurveyUnep: FC<TwoColumnsSurveyUnepProps> = ({items}) => {
    const middleIndex = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, middleIndex);
    const secondHalf = items.slice(middleIndex);

    return(
        <TwoColumnsValueWrapper>
            <div>
                {!!firstHalf && firstHalf.map((item) => (
                    <TwoColumnsValueCell key={item.id} isBold={item.type === 'both'}>
                        {`${item.title}`}
                    </TwoColumnsValueCell>
                ))}
            </div>
            <div>
                {!!secondHalf && secondHalf.map((item) => (
                    <TwoColumnsValueCell key={item.id} isBold={item.type === 'both'}>
                        {`${item.title}`}
                    </TwoColumnsValueCell>
                ))}
            </div>
        </TwoColumnsValueWrapper>
    );
};

export const Evidence: FC<EvidenceProps> = ({value}) => {
    const val = parseFloat(value as string) ?? null;

    if (val !== null) {
        if (val < 0.5) {
            return (
                <EvidenceWrapper>
                    <EvidenceText style={{color: '#dc661e'}}>No Evidence</EvidenceText>
                    <Rollover>No current classification, no identified studies</Rollover>
                </EvidenceWrapper>
            );
        } else if (val === 0.5) {
            return (
                <EvidenceWrapper>
                    <EvidenceText style={{color: '#004994'}}>Some Evidence</EvidenceText>
                    <Rollover>Some classification but not wide agreement</Rollover>
                </EvidenceWrapper>
            );
        } else if (val > 0.5) {
            return (
                <EvidenceWrapper>
                    <EvidenceText style={{color: '#008d58'}}>Sufficient Evidence</EvidenceText>
                    <Rollover>Wide agreement on classification</Rollover>
                </EvidenceWrapper>
            )
        }
    }

    return (
        <EvidenceWrapper>
            <EvidenceText style={{color: '#7F7F7F'}}>Not Classified</EvidenceText>
        </EvidenceWrapper>
    )
};

export const ClassificationListRow: FC<ClassificationListRowProps> = ({label, value, desc, isDescShown = false}) => {
    return <>
        <TitleCell>
            <Label>{label}</Label>
            {isDescShown && <Desc>{desc}</Desc>}
        </TitleCell>
        <ValueCell>
            <Evidence value={value}/>
        </ValueCell>
    </>
};
