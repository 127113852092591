import React, {FC} from 'react';
import {StyledAside, StyledButton} from "./styled";
import {UserStatus} from "types";

type Props = {
    tab: UserStatus,
    setTab: (value: string) => void;
}

const tabs: Array<{alias: UserStatus, title: string}> = [
    {
        alias: 'pending',
        title: 'Pending',
    },
    {
        alias: 'active',
        title: 'Active',
    },
    {
        alias: 'archived',
        title: 'Archived',
    },
]

const AsideBlack: FC<Props> = ({setTab, tab = 'overview'}) => {
    return (
        <StyledAside>
            <>
                {tabs.map((item) => (
                    <StyledButton
                        key={item.alias}
                        title={item.title}
                        variant='aside-black'
                        size='XL'
                        color='transparent'
                        active={tab === item.alias}
                        onClick={() => setTab(item.alias)}
                    />
                ))}
            </>
        </StyledAside>
    );
};

export default AsideBlack;
