import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import {showSuccessAlert} from '../alert/alertSlice'
import {IChemical, IField} from "../../types";
import FileSaver from "file-saver";

export const fetchFields = createAsyncThunk('chemical/fields', async () => {
    const response = await api.get('chemical/fields');
    return response.data;
});

export const fetchChemicals = createAsyncThunk('chemical/list', async (params: any) => {
    const response = await api.get('chemical/list', { params });
    return response.data;
});

export const fetchChemicalById = createAsyncThunk('chemical/get', async (id: string) => {
    const response = await api.get(`chemical/${id}/get`);
    return response.data;
});

export const fetchCreateChemical = createAsyncThunk('chemical/create', async (params: any, thunkAPI) => {
    const {data, cb} = params;
    try {
        const response = await api.post(`chemical/create`, data)
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message, cb}));
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
)

export const fetchChemicalsExport = createAsyncThunk('chemical/export', async () => {
    return await api.get(`export/chemicals`, {responseType: "blob"});
});

export const fetchUpdateChemical = createAsyncThunk('chemical/update', async (params: any, thunkAPI) => {
    const {id, data, cb} = params;
    try {
        const response = await api.post(`chemical/${id}/update`, data)
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message, cb}));
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
)

export const fetchDeleteChemical = createAsyncThunk('chemical/delete', async (id: number) => {
    const response = await api.post(`chemical/${id}/delete`);
    return response.data;
});

interface ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    search: string,
    data_source: string,
    name: string,
    casrn: string,
    inchi_key: string,
    confidence_plastic_commerce: string,
    level_of_concern: string,
    polymer: string,
    function: string,
    industrial_sector: string,
    is_active: string,
}

interface Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface ChemicalState {
    chemicals: Array<IChemical>;
    chemical: IChemical | null;
    fields: {
        [key: string]: Array<IField>
    };
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
    isExporting: boolean;
}

const initialState: ChemicalState = {
    chemicals: [],
    chemical: null,
    fields: {},
    listFilters: {
        order_by: 'name',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        search: '',
        data_source: '',
        name: '',
        casrn: '',
        inchi_key: '',
        confidence_plastic_commerce: '',
        level_of_concern: '',
        polymer: '',
        function: '',
        industrial_sector: '',
        is_active: '1',
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
    isExporting: false,
};

export const chemicalSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        resetChemicalDetails: (state) => {
            state.chemical = initialState.chemical;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchFields.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchFields.fulfilled, (state: any, action: any) => {
                state.fields = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchFields.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchChemicals.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchChemicals.fulfilled, (state: any, action: any) => {
                const {data, current_page, total, last_page, ipp, per_page, from, to} = action.payload.data.resource;
                state.chemicals = data;
                state.pagination = {
                    ...state.pagination,
                    current_page: current_page,
                    total: total,
                    last_page: last_page,
                    ipp: ipp,
                    page: current_page,
                    per_page: per_page,
                    from: from,
                    to: to,
                }
                state.isLoading = false;
                state.isAutocompleteLoading = false;
            })
            .addCase(fetchChemicals.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchChemicalById.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchChemicalById.fulfilled, (state: any, action: any) => {
                state.chemical = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchChemicalById.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCreateChemical.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateChemical.fulfilled, (state: any, action: any) => {
                state.chemical = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchCreateChemical.rejected, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateChemical.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateChemical.fulfilled, (state: any, action: any) => {
                state.chemical = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchUpdateChemical.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteChemical.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteChemical.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteChemical.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchChemicalsExport.pending, state => {
                state.isLoading = true;
                state.isExporting = true;
            })
            .addCase(fetchChemicalsExport.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
                state.isExporting = false;
                FileSaver.saveAs(action.payload.data, "chemicals.xlsx");
            })
            .addCase(fetchChemicalsExport.rejected, state => {
                state.isLoading = false;
                state.isExporting = false;
            })
    },
});

export const { setFilterParams, resetFilters, resetChemicalDetails } = chemicalSlice.actions;

export default chemicalSlice.reducer;
