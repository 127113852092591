import React, {FC} from 'react';
import {IChemical, IForm} from "types";
import {FormTableRow} from "components/pages/ChemicalDetails/parts/Tabs/Common";
import {ClassificationLabelling} from "./ClassificationLabelling";
import {evidenceOptions} from "constants/SelectOptions";
import FieldDescriptions from "constants/FieldDescriptions";
import {
    ClassificationTable,
    GroupHeader,
    WideTableHeader
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    form: IForm;
    chemical: IChemical | null;
    isDescShown: boolean;
}

const ClassificationForm: FC<Props> = ({form, chemical, isDescShown}) => {
    return (
        <div>
            <ClassificationLabelling classifications={chemical?.classifications || []}/>
            <GroupHeader>Globally Harmonized System of Classification & Labelling of Chemicals (GHS)</GroupHeader>
            <ClassificationTable>
                <div/>
                <WideTableHeader>UNEP Identified</WideTableHeader>
                <FormTableRow
                    label="Carcinogenic"
                    form={form}
                    field={{
                        name: 'carcinogenic_c',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.carcinogenic_c}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Mutagenic"
                    form={form}
                    field={{
                        name: 'mutagenic_m',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.mutagenic_m}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Reprotoxic"
                    form={form}
                    field={{
                        name: 'reprotoxic_r',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.reprotoxic_r}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="STOT RE"
                    form={form}
                    field={{
                        name: 'specific_target_organ_toxicity_repeated_exposure_stot_re',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.specific_target_organ_toxicity_repeated_exposure_stot_re}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Aquatic Toxicity"
                    form={form}
                    field={{
                        name: 'aquatic_toxicity_aqtox',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.aquatic_toxicity_aqtox}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Respiratory Sensitizer"
                    form={form}
                    field={{
                        name: 'respiratory_sensitization_respsens',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.respiratory_sensitization_respsens}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Skin Sensitizer"
                    form={form}
                    field={{
                        name: 'skin_sensitizer',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.skin_sensitizer}
                    isDescShown={isDescShown}
                />
            </ClassificationTable>

            <GroupHeader>Regional Classifications</GroupHeader>
            <ClassificationTable>
                <div/>
                <WideTableHeader>UNEP Identified</WideTableHeader>
                <FormTableRow
                    label="PBT: persistent, bioaccumulative and toxic"
                    form={form}
                    field={{
                        name: 'pbt',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.pbt}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Endocrine Mode of Action"
                    form={form}
                    field={{
                        name: 'endocrine_disrupting_edc',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.endocrine_disrupting_edc}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="vPvB: very persistent and very bioaccumulative"
                    form={form}
                    field={{
                        name: 'vpvb',
                        type: 'select',
                        options: evidenceOptions,
                    }}
                    desc={FieldDescriptions.vpvb}
                    isDescShown={isDescShown}
                />
            </ClassificationTable>
        </div>
    );
};

export default ClassificationForm;
