import styled from "styled-components";
import arrow from "assets/images/icons/breadcrumb_arrow.svg";
import {Link} from "react-router-dom";

export const Wrapper = styled.div`
  padding: 30px 0 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.blackTextColor};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  font-family: ${(props) => props.theme.fontFamilySecondary};
`;

export const Breadcrumbs = styled.ul`
  display: flex;
  margin: 15px 0 0 0;
  padding: 0;
  list-style-type: none;
`;

export const BreadcrumbItem = styled.li`
  display: flex;
  color: ${(props) => props.theme.blackTextColor};
  align-items: center;
  
  &:not(:first-child):before {
    display: flex;
    content: url(${arrow});
    padding: 0;
    height: 10px;
    width: 10px;
    vertical-align: middle;
    align-items: center;
    margin: 2px 5px 0 5px;
  }

  &:last-child {
    color: #666;
    pointer-events: none;
  }
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
  color: inherit;
`;
