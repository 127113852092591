import React, {FC, useMemo} from 'react';
import {Map} from "components/common";
import {
    Australia,
    Canada,
    China,
    EU,
    India,
    Japan,
    Malaysia,
    Mexico,
    NewZealand,
    NordicCountries,
    OECD,
    Philippines,
    Russia,
    SouthKorea,
    Switzerland,
    Taiwan,
    Thailand,
    Turkey,
    UnitedStates,
    Vietnam
} from "./resources";
import {GroupHeader} from "../DataSources/styled";
import {
    MapWrapper,
    RegionsWrapper,
    CountriesWrapper,
    Countries,
    Country,
    CountryTitle,
    NoTitle,
    BlockWrapper
} from "./styled";

type Props = {
    regions: Array<string>
}

type Region = {
    pos: number,
    alias: Array<string>,
    title: string,
    codes: Array<string>,
    icon: string
}

const euCountries = [
    "AUT", "BEL", "BGR", "HRV", "CYP", "CZE", "DNK", "EST", "FIN", "FRA",
    "DEU", "GRC", "HUN", "IRL", "ITA", "LVA", "LTU", "LUX", "MLT", "NLD",
    "POL", "PRT", "ROU", "SVK", "SVN", "ESP", "SWE"
];

const oecdCountries = [
    "AUS", "AUT", "BEL", "CAN", "CHL", "COL", "CZE", "DNK", "EST", "FIN",
    "FRA", "DEU", "GRC", "HUN", "ISL", "IRL", "ISR", "ITA", "JPN", "KOR",
    "LVA", "LTU", "LUX", "MEX", "NLD", "NZL", "NOR", "POL", "PRT", "SVK",
    "SVN", "ESP", "SWE", "CHE", "TUR", "GBR", "USA"
];

const nordicCountries = [
    "DNK", "FIN", "ISL", "NOR", "SWE"
];

const regionsList: Array<Region> = [
    {pos: 1, alias: ['canada'], title: 'Canada', icon: Canada, codes: ['CAN']},
    {pos: 1, alias: ['australia'], title: 'Australia', icon: Australia, codes: ['AUS']},
    {pos: 1, alias: ['china'], title: 'China', icon: China, codes: ['CHN']},
    {pos: 1, alias: ['india'], title: 'India', icon: India, codes: ['IND']},
    {pos: 1, alias: ['japan'], title: 'Japan', icon: Japan, codes: ['JPN']},
    {pos: 1, alias: ['malaysia'], title: 'Malaysia', icon: Malaysia, codes: ['MYS']},
    {pos: 1, alias: ['mexico'], title: 'Mexico', icon: Mexico, codes: ['MEX']},
    {pos: 1, alias: ['newzealand'], title: 'New Zealand', icon: NewZealand, codes: ['NZL']},
    {pos: 1, alias: ['philippines'], title: 'Philippines', icon: Philippines, codes: ['PHL']},
    {pos: 1, alias: ['southkorea'], title: 'Republic of Korea', icon: SouthKorea, codes: ['KOR']},
    {pos: 1, alias: ['russia'], title: 'Russia', icon: Russia, codes: ['RUS']},
    {pos: 1, alias: ['thailand'], title: 'Thailand', icon: Thailand, codes: ['THA']},
    {pos: 1, alias: ['turkey'], title: 'Turkey', icon: Turkey, codes: ['TUR']},
    {pos: 1, alias: ['vietnam'], title: 'Vietnam', icon: Vietnam, codes: ['VNM']},
    {pos: 1, alias: ['switzerland'], title: 'Switzerland', icon: Switzerland, codes: ['CHE']},
    {pos: 1, alias: ['taiwan'], title: 'Chinese Taipei', icon: Taiwan, codes: ['TWN']},
    {pos: 1, alias: ['unitedstates'], title: 'United States', icon: UnitedStates, codes: ['USA']},
    {pos: 2, alias: ['eu'], title: 'EU', icon: EU, codes: euCountries},
    {pos: 3, alias: ['nordiccountries'], title: 'Nordic Countries', icon: NordicCountries, codes: nordicCountries},
    {pos: 4, alias: ['oecd'], title: 'OECD', icon: OECD, codes: oecdCountries},
]

const Regions: FC<Props> = ({regions = []}) => {
    const items = useMemo(() => {
        return !!regions && regions.map(region => {
            return regionsList.find(item => item.alias.includes(region.toLowerCase().replaceAll(' ', '')));
        }).filter(item => item !== undefined) || [];
    }, [regions]);

    const codes = useMemo(() => {
        return !!items && items.map(item => item?.codes).flat().filter(item => item !== undefined && item !== '') || [];
    }, [items]);

    return (
        <RegionsWrapper>
            <GroupHeader>Regions</GroupHeader>
            <BlockWrapper>
                <MapWrapper>
                    <Map
                        countries={codes as Array<string>}
                        regions={items as Array<Region>}
                        responsive
                    />
                </MapWrapper>
            </BlockWrapper>
            <CountriesWrapper>
                <GroupHeader>Regions where this Substance is Registered</GroupHeader>
                {items && !!items.length ? (
                    <Countries>
                        {items.map((item) => (
                            item && (
                                <Country key={item.title}>
                                    <img width={25} height={16} src={item.icon || ''} alt={''}/>
                                    <CountryTitle>
                                        {item.title}
                                    </CountryTitle>
                                </Country>
                            )
                        ))}
                    </Countries>
                ) : (
                    <NoTitle>There are no associated regions</NoTitle>
                )}
            </CountriesWrapper>
        </RegionsWrapper>
    )
};

export default Regions;
