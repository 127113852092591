import {ITab} from "types";

export const tabsList: Array<ITab> = [
    {
        alias: 'overview',
        title: 'Overview',
    },
    {
        alias: 'identity',
        title: 'Chemical Identity',
    },
    {
        alias: 'properties',
        title: 'Physical Chemical Properties',
    },
    {
        alias: 'use',
        title: 'Use',
    },
    {
        alias: 'classification',
        title: 'Classification & Labelling',
    },
    {
        alias: 'regulation',
        title: 'Regulation',
    },
    {
        alias: 'hazard',
        title: 'Hazard',
    },
    {
        alias: 'exposure',
        title: 'Exposure',
    },
    {
        alias: 'risk',
        title: 'Risk',
    }
]
