import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    display: block;
`;

const isError = () => (props: any) => {
    if (props.error) {
        return {
            borderColor: 'red'
        }
    }
}

export const TextareaStyled = styled.textarea`
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.blackTextColor};
  border: 1px solid rgba(38, 38, 38, 0.2);
  border-radius: 4px;
  overflow: auto;
  height: auto;
  ${isError()};
  
  &:focus{
    outline: none;
  }
  
  &::placeholder {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    color: #a4b1b4;
  }

  &:disabled {
    background-color: ${(props) => props.theme.disabledBackgroundColor};
    color: ${(props) => props.theme.disabledTextColor};
  }
`;
