import React, {FC} from 'react';
import {IChemical} from "types";
import FieldDescriptions from "constants/FieldDescriptions";
import {
    ListTableRow,
    SubstanceImage,
    YesNoValue,
    TwoColumnsValue,
    Link
} from "components/pages/ChemicalDetails/parts/Tabs/Common";
import {DefaultTable, GroupHeader} from "./../styled";

type Props = {
    chemical: IChemical | null;
    isDescShown: boolean;
}

const IdentityList: FC<Props> = ({chemical, isDescShown}) => {
    const isSubstanceTypeShown = !!chemical?.uvcb || !!chemical?.organic || !!chemical?.contains_metal
        || !!chemical?.contains_silicon || !!chemical?.contains_phosphorous || !!chemical?.contains_sulfur || !!chemical?.contains_halogen;

    return (
        <div>
            <GroupHeader>Identifiers</GroupHeader>
            <DefaultTable>
                <ListTableRow label="CASRN" value={chemical?.casrn} desc={FieldDescriptions.casrn} isDescShown={isDescShown}/>
                <ListTableRow label="Name" value={chemical?.name} desc={FieldDescriptions.name} isDescShown={isDescShown} />
                <ListTableRow label="Preferred Name" value={chemical?.preferred_name} desc={FieldDescriptions.preferred_name} isDescShown={isDescShown} />
                <ListTableRow label="EC#" value={chemical?.ec} desc={FieldDescriptions.ec} isDescShown={isDescShown} />
                <ListTableRow label="InChI Key" value={chemical?.inchi_key} desc={FieldDescriptions.inchi_key} isDescShown={isDescShown} />
                <ListTableRow label="InChI" value={chemical?.inchi_string} desc={FieldDescriptions.inchi_string} isDescShown={isDescShown} />
                <ListTableRow label="SMILES" value={chemical?.smiles} desc={FieldDescriptions.smiles} isDescShown={isDescShown} />
                <ListTableRow label="Molecular Formula" value={chemical?.molecular_formula} desc={FieldDescriptions.molecular_formula} isDescShown={isDescShown} />
                <ListTableRow label="Molecular Mass Isotope" value={chemical?.molecular_mass_isotope} desc={FieldDescriptions.molecular_mass_isotope} isDescShown={isDescShown} />
                <ListTableRow label="Alternate CASRNs" value={chemical?.alternative_casrn_scifinder} desc={FieldDescriptions.alternative_casrn_scifinder} isDescShown={isDescShown} />
                <ListTableRow label="Deleted CASRNs" value={chemical?.deleted_casrn_scifinder} Component={TwoColumnsValue} desc={FieldDescriptions.deleted_casrn_scifinder} isDescShown={isDescShown} />
                <ListTableRow label="DTXSID" value={chemical?.dtxsid} url={chemical?.dtxsid_link ?? ''} desc={FieldDescriptions.dtxsid} Component={Link} isDescShown={isDescShown} />
                <ListTableRow label="Chemical Structure" value={chemical?.chemical_structure} Component={SubstanceImage} desc={FieldDescriptions.chemical_structure} isDescShown={isDescShown} />
                <ListTableRow label="Verified in Commerce" value={chemical?.verified_in_commerce} Component={YesNoValue} desc={FieldDescriptions.verified_in_commerce} isDescShown={isDescShown} />
                <ListTableRow label="Synonyms" value={chemical?.synonyms} desc={FieldDescriptions.synonyms} isDescShown={isDescShown} />
            </DefaultTable>

            {isSubstanceTypeShown && (
                <>
                    <GroupHeader>Substance Type</GroupHeader>
                    <DefaultTable>
                        <ListTableRow label="UVCB" value={chemical?.uvcb} Component={YesNoValue} showIfTrue desc={FieldDescriptions.uvcb} isDescShown={isDescShown} />
                        <ListTableRow label="Organic" value={chemical?.organic} Component={YesNoValue} showIfTrue desc={FieldDescriptions.organic} isDescShown={isDescShown} />
                        <ListTableRow label="Contains Metal" value={chemical?.contains_metal} Component={YesNoValue} showIfTrue desc={FieldDescriptions.contains_metal} isDescShown={isDescShown} />
                        <ListTableRow label="Contains Silicon" value={chemical?.contains_silicon} Component={YesNoValue} showIfTrue desc={FieldDescriptions.contains_silicon} isDescShown={isDescShown} />
                        <ListTableRow label="Contains Phosphorous" value={chemical?.contains_phosphorous} Component={YesNoValue} showIfTrue desc={FieldDescriptions.contains_phosphorous} isDescShown={isDescShown} />
                        <ListTableRow label="Contains Sulfur" value={chemical?.contains_sulfur} Component={YesNoValue} showIfTrue desc={FieldDescriptions.contains_sulfur} isDescShown={isDescShown} />
                        <ListTableRow label="Contains Halogen" value={chemical?.contains_halogen} Component={YesNoValue} showIfTrue desc={FieldDescriptions.contains_halogen} isDescShown={isDescShown} />
                    </DefaultTable>
                </>
            )}

        </div>
    );
};

export default IdentityList;
