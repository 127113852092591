import styled from "styled-components";
import {Input, PhoneInput, SingleSelect} from "components/controls";
import {Link} from "react-router-dom";
import {RadioGroup} from "@mui/material";

export const SignUpForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 540px;
  background-color: white;
  box-sizing: border-box;
  padding: 40px;
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImgStyled = styled.img`
  margin-top: 18px;
  margin-bottom: 50px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

export const A = styled(Link)`
  margin-top: 20px;
  color: ${(props) => props.theme.terracottaColor};
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
`;

export const FormInput = styled(Input)`
  width: 100%;
  max-width: 220px;
  min-width: 150px;
`;

export const FormInputFull = styled(Input)`
  width: 100%;
  min-width: 150px;
`;

export const FormSingleSelect = styled(SingleSelect)`
  width: 100%;
  max-width: 220px;
`;

export const FormPhoneInput = styled(PhoneInput)`
  width: 100%;
  max-width: 220px;
  min-width: 150px;
  
  & input {
    width: 100%;
    max-width: 220px;
    min-width: 150px;
  }
`;

export const PrivacyPolicy = styled.div`
  display: flex;
  margin-top: -15px;
`;

export const Radios = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const RadiosError = styled.div`
  margin-top: -24px;
  font-size: 12px;
  color: ${(props) => props.theme.errorColor};
`;

export const SectorWrapper = styled.div`
    display: flex;
    width: 220px;
    max-width: 277px;
    flex-direction: column;
    gap: 6px;
`;

export const Tooltip = styled.i`
    max-width: 220px;
    font-size: 10px;
`;
