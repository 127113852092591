import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BeforeUnloadComponent from 'react-beforeunload-component';
import {useAppDispatch, useAppSelector} from "hooks/global";
import {ROUTES} from "router/routes";
import {IFieldCategory, ITab} from "types";
import {ConfirmationModal, PageContentContainer} from "../../common";
import {fetchChemicalById, fetchCreateChemical, fetchUpdateChemical, resetChemicalDetails} from "store/chemical/chemicalSlice";
import {Form, List, Buttons, Dashboard, Risk, AsideBlack} from "./parts"
import {Content, Wrapper, H2Styled, Title, HeaderWrapper} from "./styled";
import {tabs} from "./parts/tabs";
import {fields} from "constants/FormFields";

const breadcrumbs = [
    {
        link: '/chemicals',
        name: 'Search & List View'
    },
    {
        link: '',
        name: 'Chemical Details'
    }
]

const Chemical: FC = () => {
    const {id} = useParams();
    const {pathname} = useLocation()
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const formRef = useRef<{handleSave: ()=>void} | null>(null);
    const {chemical, isLoading} = useAppSelector(state => state.chemical);
    const {user, role} = useAppSelector(state => state.auth);
    const [tab, setTab] = useState<ITab['alias']>('overview');
    const [isDescShown, setIsDescShown] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFormUpdated, setIsFormUpdated] = useState(false);
    const [isCancelModalShown, setIsCancelModalShown] = useState(false);

    const isViewOnly = useMemo(()=>{
        return !(role === 'admin' || role === 'staff');
    }, [role])

    const isNewChemical = useMemo(() => {
        return (pathname === '/chemical/new');
    }, [pathname]);

    const fieldsList: Array<IFieldCategory> = useMemo(() => {
        return Object.values(fields.reduce((acc:any, field) => {
            const {category, sub_category} = field;
            if (tabs.find((item) => item.alias === tab)?.items.indexOf(sub_category) !== -1) {
                const key = `${category}_${sub_category}`;
                if (!acc[key]) {
                    acc[key] = {
                        category: category,
                        sub_category: sub_category,
                        label: (sub_category === category) ? sub_category : sub_category + ' (' + category + ')',
                        fields: [],
                    };
                }
                if (chemical) {
                    if (field.name === 'function') {
                        field.value = chemical['functions'] as any ?? null;
                    } else if (field.name === 'polymer') {
                        field.value = chemical['polymers'] as any ?? null;
                    } else if (field.name === 'industrial_sector') {
                        field.value = chemical['industrial_sectors'] as any ?? null;
                    } else {
                        field.value = chemical[field.name] as any ?? null;
                    }
                }
                acc[key].fields.push(field);
            }
            return acc;
        }, {}))
    }, [chemical, tab]);

    useEffect(() => {
        if (id) {
            dispatch(fetchChemicalById(id)).then(resp => {
                if (resp.type === 'chemical/get/rejected') {
                    navigate(ROUTES.CHEMICALS, {state: {keepFilters: true}});
                }
            });
        }
        return () => {
            dispatch(resetChemicalDetails());
        };
    }, [id]);

    useEffect(() => {
        if (isNewChemical) {
            setIsEditMode(true)
        }
    }, [pathname]);


    useEffect(() => {
        setIsPageLoading(true);
        setTimeout(() => {
            setIsPageLoading(false);
        }, 300);
    }, [isEditMode, tab, isDescShown]);

    const handleSave = () => {
        formRef?.current?.handleSave();
    };

    const handleCancel = () => {
        if (isFormUpdated) {
            setIsCancelModalShown(true);
        } else {
            if (isNewChemical) {
                handleBack();
            } else {
                setIsEditMode(false);
            }
        }
    };

    const handleBack = () => {
        navigate(ROUTES.CHEMICALS, { state: { keepFilters: true } });
    };

    const handleFormSubmit = (data: any) => {
        if (isNewChemical) {
            dispatch(fetchCreateChemical({data})).then(resp => {
                if (resp?.type === 'chemical/create/fulfilled') {
                    navigate(`/chemical/${resp.payload.data.resource.id}`, { replace: true });
                    setIsEditMode(false);
                }
            });
        } else if (id) {
            dispatch(fetchUpdateChemical({id, data, cb: () => setIsEditMode(false)}));
        }
    }

    const handleSetEditMode = () => {
        setIsEditMode(true);
    }

    const handleChemicalArchive = (active: boolean) => {
        dispatch(fetchUpdateChemical({id, data: {is_active: active}}));
    }

    const handleCancelModalShownConfirm = () => {
        if (isNewChemical) {
            handleBack();
        } else {
            setIsEditMode(false);
        }
        setIsCancelModalShown(false);
    }

    const handleCancelModalShownCancel = () => {
        setIsCancelModalShown(false);
    }

    const handleModalComponentHandler = ({handleModalLeave, handleModalCancel}: any) => {
        return isFormUpdated ? (
            <ConfirmationModal
                isOpen={true}
                onClose={handleModalCancel}
                onConfirm={handleModalLeave}
                text={'Your form changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'}
            />
        ) : handleModalLeave();
    }

    return (
        <PageContentContainer
            title={'Chemical Details'}
            isLoading={isLoading || isPageLoading}
            breadcrumbs={breadcrumbs}
            aside={
                <AsideBlack
                    setTab={setTab}
                    tabs={tabs}
                    tab={tab}
                    setIsDescShown={setIsDescShown}
                    isVerified={(chemical && chemical.verified_in_commerce !== null) ? chemical.verified_in_commerce : undefined}
                />
            }
        >
            <Title>
                <HeaderWrapper>
                    <H2Styled $isViewOnly={isViewOnly}>Chemical Details: {chemical?.name}</H2Styled>
                </HeaderWrapper>
                <Buttons
                    isEditMode={isEditMode}
                    isNewChemical={isNewChemical}
                    isViewOnly={isViewOnly}
                    isActive={!!chemical && !isEditMode && !!chemical.is_active}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    handleBack={handleBack}
                    handleSetEditMode={handleSetEditMode}
                    handleChemicalArchive={handleChemicalArchive}
                />
            </Title>
            <Content>
                <Wrapper>
                    {tab === 'overview' || tab === 'risk' ? (
                        <>
                            {tab === 'overview' && <Dashboard chemical={chemical} />}
                            {tab === 'risk' && <Risk casrn={chemical?.casrn || ''}/>}
                        </>
                    ) : (
                        isEditMode
                            ? <BeforeUnloadComponent
                                blockRoute={true}
                                ignoreBeforeunloadDocument={!isFormUpdated}
                                ignoreChildrenLinks={true}
                                modalComponentHandler={handleModalComponentHandler}
                            >
                                <Form
                                    chemical={chemical}
                                    isDescShown={isDescShown}
                                    fieldsList={fieldsList}
                                    handleFormSubmit={handleFormSubmit}
                                    setIsFormUpdated={setIsFormUpdated}
                                    ref={formRef}
                                />
                            </BeforeUnloadComponent>
                            : <List
                                isDescShown={isDescShown}
                                fieldsList={chemical ? fieldsList : []}
                            />
                    )}
                </Wrapper>
                <ConfirmationModal
                    isOpen={isCancelModalShown}
                    onClose={handleCancelModalShownCancel}
                    onConfirm={handleCancelModalShownConfirm}
                    text={'Your form changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'}
                />
            </Content>
        </PageContentContainer>
    );
};

export default Chemical;
