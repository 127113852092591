import React, {FC} from 'react';
import {LabelStyled} from './styled';

type Props = {
    children: React.ReactNode;
};

const Label: FC<Props> = (props) => {
    return (
        <LabelStyled
            {...props}
        />
    );
};

export default Label;
