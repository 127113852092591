import React, {FC} from 'react';
import {Controller} from "react-hook-form";
import {Control} from "react-hook-form/dist/types";
import {IFormField} from "types";
import {Uploader} from "components/controls";

type Props = {
    item: IFormField,
    control: Control<any>
}

const FormUploader: FC<Props> = ({item, control}) => {
    const {name, rules, attrs, label} = item;
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            {...attrs}
            render={({ field: { ref, ...field } }) => (
                <Uploader
                    {...field}
                />
            )}
        />
    );
};

export default FormUploader;
