import React, {FC} from 'react';
import {IChemical} from "types";
import {ClassificationListRow} from "components/pages/ChemicalDetails/parts/Tabs/Common";
import {ClassificationLabelling} from "./ClassificationLabelling";
import FieldDescriptions from "constants/FieldDescriptions";
import {
    ClassificationTable,
    GroupHeader,
    WideTableHeader
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    chemical: IChemical | null;
    isDescShown: boolean;
}

const ClassificationList: FC<Props> = ({chemical, isDescShown}) => {
    return (
        <div>
            <ClassificationLabelling classifications={chemical?.classifications || []}/>
            <GroupHeader>Globally Harmonized System of Classification & Labelling of Chemicals (GHS)</GroupHeader>
            <ClassificationTable>
                <div/>
                <WideTableHeader>UNEP Identified</WideTableHeader>
                <ClassificationListRow
                    label="Carcinogenic"
                    value={chemical?.carcinogenic_c}
                    desc={FieldDescriptions.carcinogenic_c}
                    isDescShown={isDescShown}
                />
                <ClassificationListRow
                    label="Mutagenic"
                    value={chemical?.mutagenic_m}
                    desc={FieldDescriptions.mutagenic_m}
                    isDescShown={isDescShown}
                />
                <ClassificationListRow
                    label="Reprotoxic"
                    value={chemical?.reprotoxic_r}
                    desc={FieldDescriptions.reprotoxic_r}
                    isDescShown={isDescShown}
                />
                <ClassificationListRow
                    label="STOT RE"
                    value={chemical?.specific_target_organ_toxicity_repeated_exposure_stot_re}
                    desc={FieldDescriptions.specific_target_organ_toxicity_repeated_exposure_stot_re}
                    isDescShown={isDescShown}
                />
                <ClassificationListRow
                    label="Aquatic Toxicity"
                    value={chemical?.aquatic_toxicity_aqtox}
                    desc={FieldDescriptions.aquatic_toxicity_aqtox}
                    isDescShown={isDescShown}
                />
                <ClassificationListRow
                    label="Respiratory Sensitizer"
                    value={chemical?.respiratory_sensitization_respsens}
                    desc={FieldDescriptions.respiratory_sensitization_respsens}
                    isDescShown={isDescShown}
                />
                <ClassificationListRow
                    label="Skin Sensitizer"
                    value={""}
                    desc={FieldDescriptions.skin_sensitizer}
                    isDescShown={isDescShown}
                />
            </ClassificationTable>

            <GroupHeader>Regional Classifications</GroupHeader>
            <ClassificationTable>
                <div/>
                <WideTableHeader>UNEP Identified</WideTableHeader>
                <ClassificationListRow
                    label="PBT: persistent, bioaccumulative and toxic"
                    value={chemical?.pbt}
                    desc={FieldDescriptions.pbt}
                    isDescShown={isDescShown}
                />
                <ClassificationListRow
                    label="Endocrine Mode of Action"
                    value={chemical?.endocrine_disrupting_edc}
                    desc={FieldDescriptions.endocrine_disrupting_edc}
                    isDescShown={isDescShown}
                />
                <ClassificationListRow
                    label="vPvB: very persistent and very bioaccumulative"
                    value={chemical?.vpvb}
                    desc={FieldDescriptions.vpvb}
                    isDescShown={isDescShown}
                />
            </ClassificationTable>
        </div>
    );
};

export default ClassificationList;
