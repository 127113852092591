import React, {FC, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'hooks/global';
import {Spinner} from "components/common";
import Logo from 'assets/images/logo.svg'
import {
    A,
    ButtonWrapper,
    ImgStyled,
    SignUpForm,
    FieldsWrapper,
    FormInput,
    FormSingleSelect,
    Wrapper,
    FormPhoneInput,
    PrivacyPolicy, Tooltip, SectorWrapper,
} from "./styled";
import {Controller, useForm, useWatch} from "react-hook-form";
import {Button, Checkbox, Textarea} from "components/controls";
import {fetchSignUp} from "store/auth/authSlice";
import {emailPattern} from "helpers/validationRules";
import {fetchCountries, fetchStates} from "store/general/generalSlice";
import {ICountry, ISelectOption, IState, IUserFormData} from "types";
import {ROUTES} from "router/routes";
import {isValidPhoneNumber} from "libphonenumber-js";
import {APP_URI} from "constants/api";
import {purposeOptions, sectorOptions} from "constants/SelectOptions";

const SignUp: FC = () => {
    const warrantiesDisclaimerUrl = `${APP_URI}/privacy-policy.pdf`;
    const endUserLicenseUrl = `${APP_URI}/privacy-policy.pdf`;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isLoading} = useAppSelector(state => state.auth);
    const {countries, states} = useAppSelector(state => state.general);
    const {control, handleSubmit, getValues, reset, setValue, formState: {errors, touchedFields, isValid}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            phone: '',
            first_name: '',
            last_name: '',
            country_id: 1,
            organization: '',
            sector: '',
            function: '',
            agreeDisclaimer: false,
            agreeLicense: false,
        }
    });

    const sector = useWatch({
        control,
        name: 'sector',
    });

    const agreeDisclaimer = useWatch({
        control,
        name: 'agreeDisclaimer',
    });

    const agreeLicense = useWatch({
        control,
        name: 'agreeLicense',
    });

    useEffect(() => {
        dispatch(fetchCountries());
        dispatch(fetchStates());
    }, []);

    const countriesOptions = useMemo(() => {
        return countries ? countries.map((item: ICountry): ISelectOption => ({label: item.title, value: item.id})) : []
    }, [countries]);

    const submitButtonStatus = () => {
        return !(!Object.keys(errors).length || isValid) || isLoading || !agreeDisclaimer || !agreeLicense;
    };

    const handleOnSubmit = (data: Omit<IUserFormData, | 'password'>) => {
        dispatch(fetchSignUp({data})).then(resp => {
            if (resp?.type === 'auth/sign-up/fulfilled') {
                reset();
                navigate('/sign-in');
            }
        });
    }

    const handleFormSubmit = () => {
        handleSubmit((data) => handleOnSubmit(data))();
    }

    return (
        <SignUpForm>
            <ImgStyled src={Logo}/>
            <Spinner isLoading={isLoading}/>
            <Wrapper>
                <FieldsWrapper>
                    <Controller
                        name='first_name'
                        control={control}
                        rules={{required: 'This field is required'}}
                        render={({field: {ref, ...field}}) => (
                            <FormInput
                                {...field}
                                label='First Name*'
                                placeholder='First Name'
                                error={errors.first_name}
                            />
                        )}
                    />
                    <Controller
                        name='last_name'
                        control={control}
                        rules={{required: 'This field is required'}}
                        render={({field: {ref, ...field}}) => (
                            <FormInput
                                {...field}
                                label='Last Name*'
                                placeholder='Last Name'
                                error={errors.last_name}
                            />
                        )}
                    />
                    <Controller
                        name='email'
                        control={control}
                        rules={{
                            required: "This field is required",
                            pattern: emailPattern,
                        }}
                        render={({field: {ref, ...field}}) => (
                            <FormInput
                                {...field}
                                label='Email Address*'
                                placeholder='Email Address'
                                error={errors.email}
                            />
                        )}
                    />
                    <Controller
                        name='phone'
                        control={control}
                        rules={{
                            required: "This field is required",
                            validate: (value: string) => isValidPhoneNumber(`+${value}`) || 'Phone number is invalid'
                        }}
                        render={({field: {ref, ...field}}) => (
                            <FormPhoneInput
                                {...field}
                                label='Mobile Phone #*'
                                error={errors.phone}
                            />
                        )}
                    />
                </FieldsWrapper>
                <FieldsWrapper>
                    <Controller
                        name='country_id'
                        control={control}
                        render={({field: {ref, ...field}}) => (
                            <FormSingleSelect
                                {...field}
                                label='Country'
                                options={countriesOptions}
                                error={errors.country_id}
                            />
                        )}
                    />
                    <Controller
                        name='sector'
                        rules={{required: 'This field is required'}}
                        control={control}
                        render={({field: {ref, ...field}}) => (
                            <SectorWrapper>
                                <FormSingleSelect
                                    {...field}
                                    label='Sector *'
                                    options={sectorOptions}
                                    error={errors.sector}
                                />
                                <Tooltip>*If your Sector is not listed, please contact us.</Tooltip>
                            </SectorWrapper>
                        )}
                    />
                </FieldsWrapper>

                <FieldsWrapper>
                    {sector === 'industry'
                        ? <Controller
                            name='function'
                            rules={{required: 'This field is required'}}
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormSingleSelect
                                    {...field}
                                    label='Purpose *'
                                    options={purposeOptions}
                                    error={errors.function}
                                />
                            )}
                        />
                        : <Controller
                            name='function'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <Textarea
                                    {...field}
                                    label='Purpose'
                                    error={errors.function}
                                />
                            )}
                        />
                    }
                </FieldsWrapper>
                <FieldsWrapper>
                    <Controller
                        name='organization'
                        control={control}
                        rules={{required: 'This field is required'}}
                        render={({field: {ref, ...field}}) => (
                            <FormInput
                                {...field}
                                label='Organization Affiliation*'
                                placeholder='Organization Affiliation'
                                error={errors.organization}
                            />
                        )}
                    />
                </FieldsWrapper>
                <PrivacyPolicy>
                    <Controller
                        name='agreeDisclaimer'
                        control={control}
                        render={({field: {ref, ...field}}) => (
                            <Checkbox
                                {...field}
                                label={<span style={{'fontSize': '15px'}}>
                                    I have read and agree to the <A to={'#'} target="_blank" rel="noreferrer">Reps and Warranties Disclaimer</A>
                                </span>}
                            />
                        )}
                    />
                </PrivacyPolicy>
                <PrivacyPolicy>
                    <Controller
                        name='agreeLicense'
                        control={control}
                        render={({field: {ref, ...field}}) => (
                            <Checkbox
                                {...field}
                                label={<span style={{'fontSize': '15px'}}>
                                    I have read and agree to the <A to={'#'} target="_blank" rel="noreferrer">End User License Agreement</A>
                                </span>}
                            />
                        )}
                    />
                </PrivacyPolicy>
            </Wrapper>
            <ButtonWrapper>
                <Button
                    title='REGISTER TODAY!'
                    onClick={handleFormSubmit}
                    size='XXL'
                    color='green'
                    variant='secondary'
                    disabled={submitButtonStatus()}
                />
            </ButtonWrapper>
            <A to={ROUTES.SIGN_IN}>
                Already have an account? Login
            </A>
        </SignUpForm>
    );
};

export default SignUp;
