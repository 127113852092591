import React, {FC} from 'react';
import {CitationRow, CitationText, GroupHeader, P} from "components/pages/ChemicalDetails/parts/Tabs/styled";
import {ReactComponent as TitleIcon} from "assets/images/icons/title-icon.svg";
import {ReactComponent as LinkIcon} from "assets/images/icons/link-icon.svg";
import {ReactComponent as DescIcon} from "assets/images/icons/desc-icon.svg";
import {ICitation} from "types";
import {Box} from "@mui/material";

type Props = {
    citations: Array<ICitation>
}

type RegulationItemProps = {
    citation: ICitation
}

const Citation: FC<RegulationItemProps> = ({citation}) => {
    return <>
    {!!citation.citation && (
        <>
            <GroupHeader>{citation.jurisdiction}</GroupHeader>
            {!!citation.citation && (
                <CitationRow>
                    <TitleIcon/>
                    <CitationText>
                        {citation.citation}
                    </CitationText>
                </CitationRow>
            )}
            {!!citation.url && (
                <CitationRow>
                    <LinkIcon/>
                    <CitationText>
                        <a href={citation.url} target={'_blank'} rel="noreferrer">{citation.url}</a>
                    </CitationText>
                </CitationRow>
            )}
            {!!citation.summary && (
                <CitationRow>
                    <DescIcon/>
                    <CitationText>
                        {citation.summary}
                    </CitationText>
                </CitationRow>
            )}
        </>
    )}
    </>
}

export const RegulationDetails: FC<Props> = ({citations}) => {
    return <>
        <GroupHeader>Regulation Details</GroupHeader>
        {
            (!!citations && !!citations.length)
                ? citations.map((item: ICitation) => <Citation citation={item} key={item.casrn}/>)
                : <Box>No data available for this section</Box>
        }
    </>
};

