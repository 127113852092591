import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'hooks/global';
import {Spinner} from "components/common";
import Logo from 'assets/images/logo.svg'
import {A, ButtonWrapper, FormGroup, H3, ImgStyled, Form} from "./styled";
import {Controller, useForm} from "react-hook-form";
import {Button, Input} from "components/controls";
import {fetchForgotPassword} from "store/auth/authSlice";
import {ROUTES} from "router/routes";
import {emailPattern} from "helpers/validationRules";

type FormProps = {
    username: string,
}

const ForgotPassword: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isLoading} = useAppSelector(state => state.auth);
    const {control, handleSubmit, getValues, reset, formState: {errors, touchedFields, isValid}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            username: '',
        }
    });

    const submitButtonStatus = () => {
        return !(!Object.keys(errors).length || isValid) || isLoading;
    };

    const handleOnSubmit = (data: FormProps) => {
        dispatch(fetchForgotPassword({username: data.username}))
            .then(resp => {
                if (resp?.type === 'auth/forgot-password/fulfilled') {
                    reset();
                    navigate('/sign-in');
                }
        });
    }

    const handleFormSubmit = () => {
        handleSubmit((data) => handleOnSubmit(data))();
    }

    return (
        <Form>
            <ImgStyled src={Logo}/>
            <Spinner isLoading={isLoading}/>
            <H3>Forgot Password</H3>
            <FormGroup>
                <Controller
                    name={"username"}
                    control={control}
                    rules={{
                        required: "This field is required",
                        pattern: emailPattern
                    }}
                    render={({ field: { ref, ...field } }) => (
                        <Input
                            {...field}
                            label={'Email'}
                            placeholder={'Enter Email'}
                            inputSize='XL'
                            width={320}
                            error={errors.username}
                        />
                    )}
                />
            </FormGroup>
            <ButtonWrapper>
                <Button
                    title='RESET PASSWORD'
                    onClick={handleFormSubmit}
                    size='XXL'
                    color='green'
                    variant='secondary'
                    disabled={submitButtonStatus()}
                />
            </ButtonWrapper>
            <A to={ROUTES.SIGN_IN}>Back to Sign In</A>
        </Form>
    );
};

export default ForgotPassword;
