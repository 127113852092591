import React, {FC} from 'react';
import {IChemical, IForm} from "types";
import {ComingSoon, GroupHeader} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    form: IForm;
    chemical: IChemical | null;
    isDescShown: boolean;
}

const ExposureForm: FC<Props> = ({form, chemical, isDescShown}) => {
    return (
        <div>
            <GroupHeader>Exposure</GroupHeader>
            <ComingSoon>Coming Soon!</ComingSoon>
        </div>
    );
};

export default ExposureForm;
