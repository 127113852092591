import React, {FC} from 'react';
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Placeholder} from "../MultiSelect/styled";
import {defaultTheme} from "themes/main";
import {StyledLabel} from "./styled";
import {ISelectOption} from "types";
import {SelectProps} from "@mui/material/Select/Select";
import {Error} from "../Input/styled";

export interface SingleSelectProps extends SelectProps {
    onChange?: any;
    options: Array<ISelectOption>;
    placeholder?: string;
    className?: string;
    value: string | number | null;
    label?: string;
    error?: any;
    disabled?: boolean;
}

const SingleSelect: FC<SingleSelectProps> = ({className, value, label= '', disabled= false, onChange, options, error, ...props}) => {

    const getPlaceholder = (title: string | undefined) => {
        return <Placeholder>{title || ''}</Placeholder>;
    };

    return (
        <FormControl className={className}>
            {!!label.length && (
                <StyledLabel>{label}</StyledLabel>
            )}
            <Select
                value={value === null ? '' : value}
                onChange={onChange}
                displayEmpty
                disabled={disabled}
                renderValue={(selected) => {
                    if (selected !== undefined) {
                        return options?.find(item => item.value === selected)?.label ?? '';
                    } else {
                        return getPlaceholder(props.placeholder);
                    }
                }}
                style={{
                    height: '44px',
                    fontFamily: defaultTheme.fontFamily,
                    fontSize: '16px',
                    backgroundColor: "white",
                    minWidth: '150px'
                }}
                sx={{
                    color: '#000',
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                        border: '1px solid',
                        borderRadius: 0,
                        borderColor: error ? 'red' : '#D0E0E5',
                    },
                    "&.Mui-disabled": {
                        backgroundColor: `${defaultTheme.disabledBackgroundColor} !important`,
                    },
                    "&>.MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: defaultTheme.disabledTextColor,
                    },
                    "&>.MuiSelect-iconOutlined": {
                        fill: '#0099CA'
                    }
                }}
                {...props}
            >
                {options.map((option: any, k) => (
                    <MenuItem key={k} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
            {!!error && error.message && (
                <Error className={'errorMsg'}>
                    {`${error.message}`}
                </Error>
            )}
        </FormControl>
    );
};

export default SingleSelect;
