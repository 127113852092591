import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import {IAddress, ICountry, IState, IUser, IUserProfile, IUserRole, IUsersListItem} from "../../types";
import {showSuccessAlert} from "../alert/alertSlice";

export const fetchUsers = createAsyncThunk('users/list', async (params: any) => {
    const response = await api.get('users/list', { params });
    return response.data;
});

export const fetchUserById = createAsyncThunk('users/get', async (id: string) => {
    const response = await api.get(`users/${id}/get`);
    return response.data;
});

export const fetchUserUpdate = createAsyncThunk('users/update', async (params: any, thunkAPI) => {
    const {id, data, cb} = params;
    try {
        const response = await api.post(`users/${id}/update`, data);
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message, cb}));
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
);

export const fetchUserCreate = createAsyncThunk('users/create', async (params: any, thunkAPI) => {
    const {data, cb} = params;
    try {
        const response = await api.post('users/create', data);
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message, cb}));
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
);

interface User extends IUser {
    profile: IUserProfile,
    role: IUserRole,
    address: IAddress & {
        country: ICountry,
        state: IState
    }
}

interface ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    first_name: string,
    last_name: string,
    role: string,
    email: string,
    is_active: number,
    status: string,
}

interface Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface UserState {
    users: Array<IUsersListItem>;
    user: User | null;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: UserState = {
    users: [],
    user: null,
    listFilters: {
        order_by: 'first_name',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        first_name: '',
        last_name: '',
        role: '',
        email: '',
        status: 'pending',
        is_active: 1,
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        resetUserDetails: (state) => {
            state.user = initialState.user;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUsers.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                const {data, current_page, total, last_page, per_page, from, to} = action.payload.data.resource;
                state.users = data;
                state.pagination = {
                    ...state.pagination,
                    current_page: current_page,
                    total: total,
                    last_page: last_page,
                    per_page: per_page,
                    from: from,
                    to: to,
                };
                state.isLoading = false;
            })
            .addCase(fetchUsers.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUserById.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.user = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchUserById.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUserUpdate.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUserUpdate.fulfilled, (state, action) => {
                state.user = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchUserUpdate.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUserCreate.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUserCreate.fulfilled, (state, action) => {
                state.user = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchUserCreate.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

export const { setFilterParams, resetFilters, setUser, resetUserDetails } = userSlice.actions;

export default userSlice.reducer;
