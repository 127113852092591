import React, {FC} from 'react';
import {IChemical} from "types";
import {RegulationDetails} from "components/pages/ChemicalDetails/parts/Tabs/Regulation/Shared";

type Props = {
    chemical: IChemical | null;
    isDescShown: boolean;
}

const RegulationList: FC<Props> = ({chemical, isDescShown}) => {
    return (
        <div>
            <RegulationDetails citations={chemical?.citations || []}/>
        </div>
    );
};

export default RegulationList;
