import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 25px;
  margin: 5px 0 40px 0;
`;

export const GroupHeader = styled.h3`
  margin: 0 0 16px 0;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: ${(props) => props.theme.fontFamilySecondary};

  &:not(:first-child) {
    margin-top: 40px;
  }
`;