import React, {FC} from 'react';
import {IChemical, ICustomFields} from "types";
import {ReactComponent as BadgeSurvey} from 'assets/images/icons/badge-survey.svg';
import {ReactComponent as BadgePlaci} from 'assets/images/icons/badge-placi.svg';
import {ReactComponent as BadgeUnep} from 'assets/images/icons/badge-unep.svg';
import {ReactComponent as SpecialChem} from 'assets/images/icons/badge-special-chem.svg';
import {GroupHeader, Wrapper} from "./styled";

type Props = {
    chemical: IChemical & {
        survey?: Array<ICustomFields>
        plasi?: Array<ICustomFields>
    } | null
}

const DataSources: FC<Props> = ({chemical}) => {
    return (
        <>
            <GroupHeader>
                Data Sources/(Chemical Identified From)
            </GroupHeader>
            <Wrapper>
                {chemical && chemical?.is_unep && (
                    <BadgeUnep/>
                )}
                {chemical && !!chemical?.is_survey && (
                    <BadgeSurvey/>
                )}
                {chemical && chemical?.is_plasi && (
                    <BadgePlaci/>
                )}
                {chemical && chemical?.is_special_chem && (
                    <SpecialChem/>
                )}
            </Wrapper>
        </>
    )
};

export default DataSources;
