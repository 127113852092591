import React, {FC, useMemo} from 'react';
import {IChemical, IChemicalOption} from "types/index";
import {TwoColumnsSurveyUnep, TwoColumnsValue} from "components/pages/ChemicalDetails/parts/Tabs/Common";
import {
    Desc,
    Label,
    MergedTitleCell,
    MergedValueCell,
    TitleCell,
    ValueCell
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    chemical: IChemical | null;
    isDescShown: boolean;
};

export const FunctionsPolymersUsesRegions: FC<Props> = ({chemical, isDescShown}) => {
    const regions = chemical?.regions
        ? chemical?.regions
            .replace('Taiwan', 'Chinese Taipei')
            .replace('taiwan', 'Chinese Taipei')
            .replace('South Korea', 'Republic of Korea')
        : '';

    const functionsUNEP = useMemo(() => {
        if (chemical && chemical.functions) {
            return chemical.functions.filter((item: IChemicalOption) => item.type === 'unep' || item.type === 'both');
        } return []
    }, [chemical]);

    const functionsSurvey = useMemo(() => {
        if (chemical && chemical.functions) {
            return chemical.functions.filter((item: IChemicalOption) => item.type === 'survey' || item.type === 'both');
        } return []
    }, [chemical]);

    const polymersUNEP = useMemo(() => {
        if (chemical && chemical.polymers) {
            return chemical.polymers.filter((item: IChemicalOption) => item.type === 'unep' || item.type === 'both');
        } return []
    }, [chemical]);

    const polymersSurvey = useMemo(() => {
        if (chemical && chemical.polymers) {
            return chemical.polymers.filter((item: IChemicalOption) => item.type === 'survey' || item.type === 'both');
        } return []
    }, [chemical]);

    const industrialSectorsUNEP = useMemo(() => {
        if (chemical && chemical.industrial_sectors) {
            return chemical.industrial_sectors.filter((item: IChemicalOption) => item.type === 'unep' || item.type === 'both');
        } return []
    }, [chemical]);

    const industrialSectorsSurvey = useMemo(() => {
        if (chemical && chemical.industrial_sectors) {
            return chemical.industrial_sectors.filter((item: IChemicalOption) => item.type === 'survey' || item.type === 'both');
        } return []
    }, [chemical]);

    return (
        <>
            <TitleCell>
                <Label>Function</Label>
                {isDescShown && <Desc>Functions that this substance might fulfill</Desc>}
            </TitleCell>
            <ValueCell>
                <TwoColumnsSurveyUnep items={functionsSurvey}/>
            </ValueCell>
            <ValueCell>
                <TwoColumnsSurveyUnep items={functionsUNEP}/>
            </ValueCell>

            <TitleCell>
                <Label>Polymer Types</Label>
                {isDescShown && <Desc>Polymers that this substance might be compatible wit</Desc>}
            </TitleCell>
            <ValueCell>
                <TwoColumnsSurveyUnep items={polymersSurvey}/>
            </ValueCell>
            <ValueCell>
                <TwoColumnsSurveyUnep items={polymersUNEP}/>
            </ValueCell>

            <TitleCell>
                <Label>Industrial Sector</Label>
                {isDescShown && <Desc>Industrial sectors in which plastics including this substance might be used</Desc>}
            </TitleCell>
            <ValueCell>
                <TwoColumnsSurveyUnep items={industrialSectorsSurvey}/>
            </ValueCell>
            <ValueCell>
                <TwoColumnsSurveyUnep items={industrialSectorsUNEP}/>
            </ValueCell>

            <MergedTitleCell>
                <Label>Regions where Substance is Registered</Label>
                {isDescShown && <Desc>Regions in which this substance is registered</Desc>}
            </MergedTitleCell>
            <MergedValueCell>
                <TwoColumnsValue value={regions}/>
            </MergedValueCell>

            <MergedTitleCell>
                <Label>High production volume chemical</Label>
                {isDescShown && <Desc></Desc>}
            </MergedTitleCell>
            <MergedValueCell>
                {regions.indexOf('OECD') != -1 ? 'OECD' : 'No OECD'}
            </MergedValueCell>
        </>
    );
};

