import styled from "styled-components";

const isError = () => (props: any) => {
    if (props.error) {
        return {
            borderColor: 'red'
        }
    }
}

export const Placeholder = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #a4b1b4;
  opacity: 0.5;
  ${isError()};
`;
