import React, {FC} from 'react';
import {IChemical} from "types";
import {
    Desc,
    GroupHeader,
    Label,
    TitleCell,
    ValueCell,
    ChemPropertiesTable,
    WideTableHeader
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    chemical: IChemical | null;
    isDescShown: boolean;
}

const PropertiesList: FC<Props> = ({chemical, isDescShown}) => {
    return (
        <div>
            <GroupHeader>Physical Chemical Properties (Predicted)</GroupHeader>
            <ChemPropertiesTable>
                <div/>
                <WideTableHeader>Median</WideTableHeader>
                <WideTableHeader>Low</WideTableHeader>
                <WideTableHeader>High</WideTableHeader>
                <WideTableHeader>Unit</WideTableHeader>

                <TitleCell>
                    <Label>Polarizability</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.polarizability_avg}</ValueCell>
                <ValueCell>{chemical?.polarizability_low}</ValueCell>
                <ValueCell>{chemical?.polarizability_high}</ValueCell>
                <ValueCell>{chemical?.polarizability_unit}</ValueCell>

                <TitleCell>
                    <Label>Henry&apos;s Law</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.henrys_law_avg}</ValueCell>
                <ValueCell>{chemical?.henrys_law_low}</ValueCell>
                <ValueCell>{chemical?.henrys_law_high}</ValueCell>
                <ValueCell>{chemical?.henrys_law_unit}</ValueCell>

                <TitleCell>
                    <Label>Boiling Point</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.boiling_point_avg}</ValueCell>
                <ValueCell>{chemical?.boiling_point_low}</ValueCell>
                <ValueCell>{chemical?.boiling_point_high}</ValueCell>
                <ValueCell>{chemical?.boiling_point_unit}</ValueCell>

                <TitleCell>
                    <Label>Flash Point</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.flash_point_avg}</ValueCell>
                <ValueCell>{chemical?.flash_point_low}</ValueCell>
                <ValueCell>{chemical?.flash_point_high}</ValueCell>
                <ValueCell>{chemical?.flash_point_unit}</ValueCell>

                <TitleCell>
                    <Label>Melting Point</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.melting_point_avg}</ValueCell>
                <ValueCell>{chemical?.melting_point_low}</ValueCell>
                <ValueCell>{chemical?.melting_point_high}</ValueCell>
                <ValueCell>{chemical?.melting_point_unit}</ValueCell>

                <TitleCell>
                    <Label>Molar Refractivity</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.molar_refractivity_avg}</ValueCell>
                <ValueCell>{chemical?.molar_refractivity_low}</ValueCell>
                <ValueCell>{chemical?.molar_refractivity_high}</ValueCell>
                <ValueCell>{chemical?.molar_refractivity_unit}</ValueCell>

                <TitleCell>
                    <Label>Molar Volume</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.molar_volume_avg}</ValueCell>
                <ValueCell>{chemical?.molar_volume_low}</ValueCell>
                <ValueCell>{chemical?.molar_volume_high}</ValueCell>
                <ValueCell>{chemical?.molar_volume_unit}</ValueCell>

                <TitleCell>
                    <Label>Viscosity</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.viscosity_avg}</ValueCell>
                <ValueCell>{chemical?.viscosity_low}</ValueCell>
                <ValueCell>{chemical?.viscosity_high}</ValueCell>
                <ValueCell>{chemical?.viscosity_unit}</ValueCell>

                <TitleCell>
                    <Label>Surface Tension</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.surface_tension_avg}</ValueCell>
                <ValueCell>{chemical?.surface_tension_low}</ValueCell>
                <ValueCell>{chemical?.surface_tension_high}</ValueCell>
                <ValueCell>{chemical?.surface_tension_unit}</ValueCell>

                <TitleCell>
                    <Label>Density</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.density_avg}</ValueCell>
                <ValueCell>{chemical?.density_low}</ValueCell>
                <ValueCell>{chemical?.density_high}</ValueCell>
                <ValueCell>{chemical?.density_unit}</ValueCell>

                <TitleCell>
                    <Label>Vapor Pressure</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.vapor_pressure_avg}</ValueCell>
                <ValueCell>{chemical?.vapor_pressure_low}</ValueCell>
                <ValueCell>{chemical?.vapor_pressure_high}</ValueCell>
                <ValueCell>{chemical?.vapor_pressure_unit}</ValueCell>

                <TitleCell>
                    <Label>Water Solubility</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.water_solubility_avg}</ValueCell>
                <ValueCell>{chemical?.water_solubility_low}</ValueCell>
                <ValueCell>{chemical?.water_solubility_high}</ValueCell>
                <ValueCell>{chemical?.water_solubility_unit}</ValueCell>

                <TitleCell>
                    <Label>Thermal Conductivity</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.thermal_conductivity_avg}</ValueCell>
                <ValueCell>{chemical?.thermal_conductivity_low}</ValueCell>
                <ValueCell>{chemical?.thermal_conductivity_high}</ValueCell>
                <ValueCell>{chemical?.thermal_conductivity_unit}</ValueCell>

                <TitleCell>
                    <Label>Index of Refraction</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.refraction_index_avg}</ValueCell>
                <ValueCell>{chemical?.refraction_index_low}</ValueCell>
                <ValueCell>{chemical?.refraction_index_high}</ValueCell>
                <ValueCell>{chemical?.refraction_index_unit}</ValueCell>

                <TitleCell>
                    <Label>LogKoa: Octanol-Air</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.logkoa_octanol_air_avg}</ValueCell>
                <ValueCell>{chemical?.logkoa_octanol_air_low}</ValueCell>
                <ValueCell>{chemical?.logkoa_octanol_air_high}</ValueCell>
                <ValueCell>{chemical?.logkoa_octanol_air_unit}</ValueCell>

                <TitleCell>
                    <Label>LogKow: Octanol-Water</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>{chemical?.logkow_octanol_water_avg}</ValueCell>
                <ValueCell>{chemical?.logkow_octanol_water_low}</ValueCell>
                <ValueCell>{chemical?.logkow_octanol_water_high}</ValueCell>
                <ValueCell>{chemical?.logkow_octanol_water_unit}</ValueCell>

                {/*<TitleCell>*/}
                {/*    <Label>pKa Acidic Apparent</Label>*/}
                {/*    {isDescShown && <Desc></Desc>}*/}
                {/*</TitleCell>*/}
                {/*<ValueCell>{chemical?.pka_acidic_apparent_avg}</ValueCell>*/}
                {/*<ValueCell>{chemical?.pka_acidic_apparent_low}</ValueCell>*/}
                {/*<ValueCell>{chemical?.pka_acidic_apparent_high}</ValueCell>*/}
                {/*<ValueCell>{chemical?.pka_acidic_apparent_unit}</ValueCell>*/}

                {/*<TitleCell>*/}
                {/*    <Label>pKa Basic ApparentEach</Label>*/}
                {/*    {isDescShown && <Desc></Desc>}*/}
                {/*</TitleCell>*/}
                {/*<ValueCell>{chemical?.pka_basic_apparent_avg}</ValueCell>*/}
                {/*<ValueCell>{chemical?.pka_basic_apparent_low}</ValueCell>*/}
                {/*<ValueCell>{chemical?.pka_basic_apparent_high}</ValueCell>*/}
                {/*<ValueCell>{chemical?.pka_basic_apparent_unit}</ValueCell>*/}

            </ChemPropertiesTable>
        </div>
    );
};

export default PropertiesList;
