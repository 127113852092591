import React, {FC} from 'react';
import {IChemical, ITab} from "types";
import {
    Overview,
    IdentityList,
    PropertiesList,
    UseList,
    ClassificationList,
    RegulationList,
    HazardList,
    ExposureList,
    Risk
} from "components/pages/ChemicalDetails/parts/Tabs";

type Props = {
    tab: ITab['alias'];
    chemical: IChemical | null;
    isDescShown: boolean;
}

const ListView: FC<Props> = ({tab, isDescShown, chemical}) => {
    const TabSelect: FC<{tab: ITab['alias']}> = ({tab = 'overview'}) => {
        if (tab === 'identity') {
            return <IdentityList chemical={chemical} isDescShown={isDescShown}/>
        } else if (tab === 'properties') {
            return <PropertiesList chemical={chemical} isDescShown={isDescShown}/>
        } else if (tab === 'use') {
            return <UseList chemical={chemical} isDescShown={isDescShown}/>
        } else if (tab === 'classification') {
            return <ClassificationList chemical={chemical} isDescShown={isDescShown}/>
        } else if (tab === 'regulation') {
            return <RegulationList chemical={chemical} isDescShown={isDescShown}/>
        } else if (tab === 'hazard') {
            return <HazardList chemical={chemical} isDescShown={isDescShown}/>
        } else if (tab === 'exposure') {
            return <ExposureList chemical={chemical} isDescShown={isDescShown}/>
        } else if (tab === 'risk') {
            return <Risk chemical={chemical}/>
        } else {
            return <Overview chemical={chemical}/>
        }
    };

    return (
        <TabSelect
            tab={tab}
        />
    );
};

export default ListView;
