import React, {FC} from 'react';
import {IChemical, IForm} from "types";
import {FormControl} from "components/pages/ChemicalDetails/parts/Tabs/Common";
import {
    ChemPropertiesTable,
    Desc,
    GroupHeader,
    Label,
    TitleCell,
    ValueCell,
    WideTableHeader
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    form: IForm;
    chemical: IChemical | null;
    isDescShown: boolean;
}

const PropertiesForm: FC<Props> = ({form, chemical, isDescShown}) => {
    return (
        <div>
            <GroupHeader>Physical Chemical Properties (Predicted)</GroupHeader>
            <ChemPropertiesTable>
                <div/>
                <WideTableHeader>Median</WideTableHeader>
                <WideTableHeader>Low</WideTableHeader>
                <WideTableHeader>High</WideTableHeader>
                <WideTableHeader>Unit</WideTableHeader>

                <TitleCell>
                    <Label>Polarizability</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "polarizability_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "polarizability_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "polarizability_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "polarizability_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Henry&apos;s Law</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "henrys_law_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "henrys_law_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "henrys_law_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "henrys_law_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Boiling Point</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "boiling_point_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "boiling_point_high",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "boiling_point_low",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "boiling_point_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Flash Point</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "flash_point_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "flash_point_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "flash_point_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "flash_point_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Melting Point</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "melting_point_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "melting_point_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "melting_point_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "melting_point_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Molar Refractivity</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "molar_refractivity_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "molar_refractivity_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "molar_refractivity_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "molar_refractivity_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Molar Volume</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "molar_volume_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "molar_volume_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "molar_volume_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "molar_volume_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Viscosity</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "viscosity_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "viscosity_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "viscosity_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "viscosity_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Surface Tension</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "surface_tension_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "surface_tension_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "surface_tension_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "surface_tension_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Density</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "density_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "density_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "density_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "density_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Vapor Pressure</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "vapor_pressure_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "vapor_pressure_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "vapor_pressure_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "vapor_pressure_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Water Solubility</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "water_solubility_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "water_solubility_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "water_solubility_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "water_solubility_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Thermal Conductivity</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "thermal_conductivity_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "thermal_conductivity_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "thermal_conductivity_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "thermal_conductivity_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Index of Refraction</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "refraction_index_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "refraction_index_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "refraction_index_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "refraction_index_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>LogKoa: Octanol-Air</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "logkoa_octanol_air_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "logkoa_octanol_air_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "logkoa_octanol_air_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "logkoa_octanol_air_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>LogKow: Octanol-Water</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "logkow_octanol_water_avg",
                            label: "Median"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "logkow_octanol_water_low",
                            label: "Low"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "logkow_octanol_water_high",
                            label: "High"
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            type: "input",
                            name: "logkow_octanol_water_unit",
                            label: "Unit"
                        }}
                    />
                </ValueCell>

                {/*<TitleCell>*/}
                {/*    <Label>pKa Acidic Apparent</Label>*/}
                {/*    {isDescShown && <Desc></Desc>}*/}
                {/*</TitleCell>*/}
                {/*<ValueCell>*/}
                {/*    <FormControl*/}
                {/*        form={form}*/}
                {/*        field={{*/}
                {/*            type: "input",*/}
                {/*            name: "pka_acidic_apparent_avg",*/}
                {/*            label: "Median"*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</ValueCell>*/}
                {/*<ValueCell>*/}
                {/*    <FormControl*/}
                {/*        form={form}*/}
                {/*        field={{*/}
                {/*            type: "input",*/}
                {/*            name: "pka_acidic_apparent_low",*/}
                {/*            label: "Low"*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</ValueCell>*/}
                {/*<ValueCell>*/}
                {/*    <FormControl*/}
                {/*        form={form}*/}
                {/*        field={{*/}
                {/*            type: "input",*/}
                {/*            name: "pka_acidic_apparent_high",*/}
                {/*            label: "High"*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</ValueCell>*/}
                {/*<ValueCell>*/}
                {/*    <FormControl*/}
                {/*        form={form}*/}
                {/*        field={{*/}
                {/*            type: "input",*/}
                {/*            name: "pka_acidic_apparent_unit",*/}
                {/*            label: "Unit"*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</ValueCell>*/}

                {/*<TitleCell>*/}
                {/*    <Label>pKa Basic ApparentEach</Label>*/}
                {/*    {isDescShown && <Desc></Desc>}*/}
                {/*</TitleCell>*/}
                {/*<ValueCell>*/}
                {/*    <FormControl*/}
                {/*        form={form}*/}
                {/*        field={{*/}
                {/*            type: "input",*/}
                {/*            name: "pka_basic_apparent_avg",*/}
                {/*            label: "Median"*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</ValueCell>*/}
                {/*<ValueCell>*/}
                {/*    <FormControl*/}
                {/*        form={form}*/}
                {/*        field={{*/}
                {/*            type: "input",*/}
                {/*            name: "pka_basic_apparent_low",*/}
                {/*            label: "Low"*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</ValueCell>*/}
                {/*<ValueCell>*/}
                {/*    <FormControl*/}
                {/*        form={form}*/}
                {/*        field={{*/}
                {/*            type: "input",*/}
                {/*            name: "pka_basic_apparent_high",*/}
                {/*            label: "High"*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</ValueCell>*/}
                {/*<ValueCell>*/}
                {/*    <FormControl*/}
                {/*        form={form}*/}
                {/*        field={{*/}
                {/*            type: "input",*/}
                {/*            name: "pka_basic_apparent_unit",*/}
                {/*            label: "Unit"*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</ValueCell>*/}

            </ChemPropertiesTable>
        </div>
    );
};

export default PropertiesForm;
