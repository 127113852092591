import styled from "styled-components";
import background from 'assets/images/bg.jpg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const PageContainer = styled.div`
  display: flex;
  min-height: 600px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${background});
`;

export const Copyright = styled.div`
text-align: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.blackTextColor};
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-size: 13px;
  text-transform: capitalize;
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    min-width: 520px;
    min-height: 100vh;
`;