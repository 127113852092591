import React, {ButtonHTMLAttributes, FC} from 'react';
import {BthImg, StyledButton} from "./styled";
import ArrowLeftImg from 'assets/images/icons/arrow-left.svg';
import EditImg from 'assets/images/icons/btn_edit.svg';
import SaveImg from 'assets/images/icons/btn_save.svg';
import CancelImg from 'assets/images/icons/cancel.svg';
import UploadImg from 'assets/images/icons/upload.svg';
import AddImg from "assets/images/icons/add.svg";
import ExportImg from "assets/images/icons/export.svg";
import ArchiveImg from "assets/images/icons/archive.svg";
import ActivateImg from "assets/images/icons/activate.svg";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLElement> {
    title: string;
    variant?: 'primary'|'secondary'|'aside'|'aside-black';
    color?: 'terracotta'|'transparent'|'green'|'blue'|'ultraviolet'|'terracotta-transparent'|'green-transparent'|'blue-transparent'|'ultraviolet-transparent'|'errorColor'|'red'|'red-transparent';
    size?: 'XXXL'|'XXL'|'XL'|'LG'|'MD'|'MD2'|'SM'|'XS'|'TN';
    icon?: string;
    active?: boolean;
}

const Button: FC<ButtonProps> = ({title = '', size = 'LG', color = 'terracotta', variant = 'primary', icon, active = false, ...props}) => {
    const isLarge = (icon === 'add' || icon === 'export');

    if (icon === 'back') icon = ArrowLeftImg
    else if (icon === 'edit') icon = EditImg
    else if (icon === 'save') icon = SaveImg
    else if (icon === 'upload') icon = UploadImg
    else if (icon === 'cancel') icon = CancelImg
    else if (icon === 'add') icon = AddImg
    else if (icon === 'export') icon = ExportImg
    else if (icon === 'archive') icon = ArchiveImg
    else if (icon === 'activate') icon = ActivateImg

    return (
        <StyledButton
            color={color}
            variant={variant}
            size={size}
            $active={active}
            {...props}
        >
            {icon && <BthImg $isLarge={isLarge} src={icon} alt={''}/>}{title}
        </StyledButton>
    );
};

export default Button;
