import React, {FC} from 'react';
import {IChemical, IForm} from "types";
import {RegulationDetails} from "components/pages/ChemicalDetails/parts/Tabs/Regulation/Shared";

type Props = {
    form: IForm;
    chemical: IChemical | null;
    isDescShown: boolean;
}

const RegulationForm: FC<Props> = ({form, chemical, isDescShown}) => {
    return (
        <div>
            <RegulationDetails/>
        </div>
    );
};

export default RegulationForm;
