import React, {FC} from 'react';
import {PageContentWrapper} from "./styled";

type Props = {
    children: React.ReactNode;
};

const PageContent: FC<Props> = (props) => {
    return (
        <PageContentWrapper>
            {props.children}
        </PageContentWrapper>
    );
};

export default PageContent;
