const FieldDescriptions: {[key: string]: string} = {
    id: '',
    substance_id: '',
    casrn: 'Chemical Abstract Service (CAS) Registry Number',
    name: 'Common or IUPAC name of chemical',
    preferred_name: '',
    inchi_key: 'Structure-based chemical identifier',
    inchi_string: 'Computer-generated 27-character identification from InChI code',
    ec: 'European Community Number - used for chemical substances on the EU market',
    smiles: 'Simplified molecular-input line-entry system (structural identifier)',
    molecular_formula: 'Molecular Formula',
    molecular_mass_isotope: 'Monoisotopic mass from accurate masses of the most abundant natural isotopes in g/mol',
    alternative_casrn_scifinder: 'Alternate CASRN (used in parallel to the active one)',
    deleted_casrn_scifinder: 'Deleted CASRNs (replaced by the active one)',
    verified_in_commerce: '',
    uvcb: 'The substance was categorized as a substance of unknown or variable composition, complex reaction product, or biological materials (UVCB); includes simple mixtures or polymers',
    organic: 'The substance contains at least one organic moiety',
    contains_metal: 'The substance contains at least one metal or metalloid element',
    contains_silicon: 'The substance contains silicon',
    contains_phosphorous: 'The substance contains phosphorous',
    contains_sulfur: 'The substance contains sulfur',
    contains_halogen: 'The substance contains at least one halogen',
    confidence_additives: '',
    confidence_plastic: '',
    confidence_commerce: '',
    pbt: 'Confidence that substance is persistent, bioaccumulative and toxic',
    vpvb: 'Confidence that substance is very persistent and very bioaccumulative',
    perisistent_P: 'Confidence that substance is persistent; degradation half-life is larger than 60 days in marine water, 40 days in fresh or estuarine water, 180 days in marine sediment, or 120 days in fresh water sediment or soil',
    very_persistent_vp: 'Confidence that substance is very persistent; degradation half-life is larger than 60 days in marine, fresh, or estuarine water or 180 days in marine sediment, fresh water sediment, or soil',
    bioaccumulative_b: 'Confidence that substance is bioaccumulative; bioconcentration factor (BCF) in aquatic species is larger than 2,000',
    very_bioaccumulative_vb: 'Confidence that substance is very bioaccumulative; bioconcentration factor (BCF) in aquatic species is larger than 5,000',
    cmr: 'Confidence that substance is a carcinogen, mutagen, or reproductive toxicant according to Regulation EC No. 1272/2008',
    carcinogenic_c: 'Confidence that substance is a carcinogen. High confidence (1.0) for GHS hazard code H350 (category1, 1A, or 1B); medium confidence (0.5) for GHS hazard code H351 (category 2)',
    mutagenic_m: 'Confidence that substance is germ cell mutagenic. High confidence (1.0) for GHS hazard code H340 (category 1, 1A, or 1B); medium confidence (0.5) for GHS hazard code H341 (category 2)',
    reprotoxic_r: 'Confidence that substance is a reproductive toxicant. High confidence (1.0) for GHS hazard code H360 or H362 (category 1, 1A, or 1B); medium confidence (0.5) for GHS hazard code H361 (category 2)',
    specific_target_organ_toxicity_repeated_exposure_stot_re: 'Confidence that specific target organ toxicity after repeated exposure (STOT RE) comprises all significant health effects, not otherwise specifically included in the GHS, that can impair function in the target organ. High confidence (1.0) for GHS hazard code H372 (category 1); medium confidence (0.5) for GHS hazard code H373 (category 2)',
    aquatic_toxicity_aqtox: 'Confidence in substance chronic aquatic toxicity (NOEC or EC10 less than 0.01 mg/L); substance causes adverse effects to aquatic organisms during exposures that are long in relation to the lifecycle of the organism. High confidence (1.0) for GHS hazard code H400 (category 1); medium confidence (0.5) for GHS hazard code H401 (category 2) or H402 (category 3)',
    respiratory_sensitization_respsens: 'Confidence that substance causes respiratory sensitization; substance that induces hypersensitivity of the airways following inhalation of the substance. High confidence (1.0) for GHS hazard code H334 (category 1, 1A, or 1B)',
    endocrine_disrupting_edc: 'Confidence that substance is an endocrine disrupting chemical according to Regulations EU No. 528/20121 and EC No. 1107/2009. High confidence (1.0) for substance on REACH EDC list, medium confidence (0.5) for substance listing under assessment or postponed',
    montreal: 'The substance is listed under the Montreal Protocol of the United Nations',
    us_ozone_depleting_substances: 'The substance is listed under the US implementation of the Montreal Protocol (i.e., the Ozone Depleting Substances List under the Clean Air Act)',
    eu_controlled_substances_montreal: 'The substance is listed under the EU implementation of the Montreal Protocol (i.e., the EU Regulations on substances that deplete the ozone layer)',
    stockholm: 'The substance is listed under the Stockholm Convention on Persistent Organic Pollutants of the United Nations (as provided by the Japanese Chemical Risk Information Platform)',
    eu_pop_directive: 'The substance is listed under the EU implementation of the Stockholm Convention (i.e., the EU Persistent Organic Pollutant regulation)',
    rotterdam: 'The substance is listed under the Rotterdam Convention (as provided by the Japanese Chemical Risk Information Platform)',
    eu_priority_informed_concent: 'The substance is listed under the EU implementation of the Rotterdam Convention (i.e., the EU Prior Informed Consent Regulation List)',
    reach_restriction: 'The substance is listed under the EU REACH restriction list',
    reach_authorisation: 'The substance is listed under the EU REACH authorization list',
    reach_svhc: 'The substance is listed under the EU REACH substances of very high concern list',
    rohs_directive: 'The substance is listed under the EU Restriction of Hazardous Substances (RoHS) directive',
    toys_directive: 'The substance is listed under the EU Toy Safety directive',
    california_proposition65: 'The substance is listed under California proposition 65',
    cscl_classi_restriction: 'The substance is listed under the Japanese CSCL Class I Substance List, which is similar to a restriction list',
    cscl_classii_authorization: 'The substance is listed under the Japanses CSCL Class II Substance list, which is similar to an authorization list',
    isha_restriction: 'The substance is listed under the Japanese ISHA Prohibited Substances list, which is similar to a restriction list',
    isha_authorisation: 'The substance is listed under the Japanese ISHA Substances Requiring Permission list, which is similar to an authorisation list',
    kreach_restriction: 'The substance is listed under South Korean REACH Prohibited Substances List, which is similar to a restriction list',
    kreach_authorisation: 'The substance is listed under South Korean REACH Restricted Substances List, which is similar to an authorization list',
    food_contact_positive_list: 'The substance appears under a positive regulatory list for food contact plastics',
    relevant_food_contact_positive_lists: 'Postive regulatory lists for food-contact plastics under which this substance is listed. Different regulations are comma-seperated',
    eu_food_contact_materials_list: 'The substance is listed under the positive list in the EU Regulation on plastic food contact materials',
    usa_generally_recognized_as_safe: 'The substance is listed under the US EPA Generally Recognized as Safe (GRAS) or the Everything added to food (EAFUS) list',
    japan_food_contact_materials_list: 'The substance is listed under the positive list in the Japanese Food Sanitation Act',
    function: 'Functions that this substance might fulfill',
    polymer: 'Polymers that this substance might be compatible with',
    industrial_sector: 'Industrial sectors in which plastics including this substance might be used',
    industrial_sector_food_contact: 'Regions in which this substance is registered',
    number_regions: 'Number of considered regions in which this substance is registered',
    regions: 'Regions in which this substance is registered',
    eu: 'Minimal tonnage in the EU according to tonnage band in metric tons per year (t/yr). Retrieved from the ECHA registered tonnage (id = 187 on Sheet S2)',
    nordic_countries_spin: 'Total tonnage in the Nordic countries in metric tons per year (t/yr); retrieved from the SPIN database',
    usa: 'Total tonnage in the USA in metric tons per year (t/yr); retrieved from the US CDR database',
    oecd: 'Minimal tonnage for substances part of the OECD high production volume chemicals list in metric tons per year (t/yr). Substances are only listed if their tonnage exceeds 1,000 t/yr. Retrieved from OECD high production volume chemicals list',
    total: 'Total tonnage in all considered regions in metric tons per year (t/yr). US and EU tonnages were combined (SPIN tonnages were used when EU was not available). OECD tonnages were used if no other tonnages were available',
    chemical_structure: '',
    synonyms: '',
    dtxsid: '',
    polarizability_avg: '',
    polarizability_low: '',
    polarizability_high: '',
    polarizability_unit: '',
    henrys_law_avg: '',
    henrys_law_low: '',
    henrys_law_high: '',
    henrys_law_unit: '',
    boiling_point_avg: '',
    boiling_point_low: '',
    boiling_point_high: '',
    boiling_point_unit: '',
    flash_point_avg: '',
    flash_point_low: '',
    flash_point_high: '',
    flash_point_unit: '',
    melting_point_avg: '',
    melting_point_high: '',
    melting_point_low: '',
    melting_point_unit: '',
    molar_refractivity_avg: '',
    molar_refractivity_high: '',
    molar_refractivity_low: '',
    molar_refractivity_unit: '',
    molar_volume_avg: '',
    molar_volume_high: '',
    molar_volume_low: '',
    molar_volume_unit: '',
    viscosity_avg: '',
    viscosity_high: '',
    viscosity_low: '',
    viscosity_unit: '',
    surface_tension_avg: '',
    surface_tension_high: '',
    surface_tension_low: '',
    surface_tension_unit: '',
    density_avg: '',
    density_low: '',
    density_high: '',
    density_unit: '',
    vapor_pressure_avg: '',
    vapor_pressure_low: '',
    vapor_pressure_high: '',
    vapor_pressure_unit: '',
    water_solubility_avg: '',
    water_solubility_low: '',
    water_solubility_high: '',
    water_solubility_unit: '',
    thermal_conductivity_avg: '',
    thermal_conductivity_low: '',
    thermal_conductivity_high: '',
    thermal_conductivity_unit: '',
    refraction_index_avg: '',
    refraction_index_low: '',
    refraction_index_high: '',
    refraction_index_unit: '',
    logkoa_octanol_air_avg: '',
    logkoa_octanol_air_low: '',
    logkoa_octanol_air_high: '',
    logkoa_octanol_air_unit: '',
    logkow_octanol_water_avg: '',
    logkow_octanol_water_low: '',
    logkow_octanol_water_high: '',
    logkow_octanol_water_unit: '',
    pka_acidic_apparent_avg: '',
    pka_acidic_apparent_low: '',
    pka_acidic_apparent_high: '',
    pka_acidic_apparent_unit: '',
    pka_basic_apparent_avg: '',
    pka_basic_apparent_low: '',
    pka_basic_apparent_high: '',
    pka_basic_apparent_unit: '',
    fish_biotransformation_halflife_avg: '',
    fish_biotransformation_halflife_low: '',
    fish_biotransformation_halflife_high: '',
    fish_biotransformation_halflife_unit: '',
    bioconcentration_factor_avg: '',
    bioconcentration_factor_low: '',
    bioconcentration_factor_high: '',
    bioconcentration_factor_unit: '',
    soil_adsorption_coefficient_avg: '',
    soil_adsorption_coefficient_low: '',
    soil_adsorption_coefficient_high: '',
    soil_adsorption_coefficient_unit: '',
    ready_biodegredation_avg: '',
    ready_biodegredation_low: '',
    ready_biodegredation_high: '',
    ready_biodegredation_unit: '',
    atmospheric_hydroxylation_rate_avg: '',
    atmospheric_hydroxylation_rate_low: '',
    atmospheric_hydroxylation_rate_high: '',
    atmospheric_hydroxylation_rate_unit: '',
    biodegregation_halflife_avg: '',
    biodegregation_halflife_low: '',
    biodegregation_halflife_high: '',
    biodegregation_halflife_unit: '',
    bioaccumulation_factor_avg: '',
    bioaccumulation_factor_low: '',
    bioaccumulation_factor_high: '',
    bioaccumulation_factor_unit: '',

    skin_sensitizer: '',
}

export default FieldDescriptions;
