import React, {FC} from 'react';
import {IChemical, IForm} from "types";
import {DefaultTable, GroupHeader} from "components/pages/ChemicalDetails/parts/Tabs/styled";
import {FormTableRow} from "components/pages/ChemicalDetails/parts/Tabs/Common";
import {booleanOptions, confidenceOptions} from "constants/SelectOptions";
import FieldDescriptions from "constants/FieldDescriptions";

type Props = {
    form: IForm;
    chemical: IChemical | null;
    isDescShown: boolean;
}

const IdentityForm: FC<Props> = ({form, chemical, isDescShown}) => {
    const isSubstanceTypeShown = !!chemical?.uvcb || !!chemical?.organic || !!chemical?.contains_metal
        || !!chemical?.contains_silicon || !!chemical?.contains_phosphorous || !!chemical?.contains_sulfur || !!chemical?.contains_halogen;

    return (
        <div>
            <GroupHeader>Identifiers</GroupHeader>
            <DefaultTable>
                <FormTableRow
                    label="CASRN"
                    form={form}
                    field={{
                        name: 'casrn',
                        type: 'input',
                        placeholder: 'CASRN',
                    }}
                    desc={FieldDescriptions.casrn}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Name"
                    form={form}
                    field={{
                        name: 'name',
                        type: 'input',
                        placeholder: 'Name',
                    }}
                    desc={FieldDescriptions.name}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Preferred Name"
                    form={form}
                    field={{
                        name: 'preferred_name',
                        type: 'input',
                        placeholder: 'Preferred Name',
                    }}
                    desc={FieldDescriptions.preferred_name}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="EC#"
                    form={form}
                    field={{
                        name: 'ec',
                        type: 'input',
                        placeholder: 'EC#',
                    }}
                    desc={FieldDescriptions.ec}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="InChI Key"
                    form={form}
                    field={{
                        name: 'inchi_key',
                        type: 'input',
                        placeholder: 'InChI Key',
                    }}
                    desc={FieldDescriptions.inchi_key}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="InChI"
                    form={form}
                    field={{
                        name: 'inchi_string',
                        type: 'input',
                        placeholder: 'InChI Key',
                    }}
                    desc={FieldDescriptions.inchi_string}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="SMILES"
                    form={form}
                    field={{
                        name: 'smiles',
                        type: 'input',
                        placeholder: 'SMILES',
                    }}
                    desc={FieldDescriptions.smiles}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Molecular Formula"
                    form={form}
                    field={{
                        name: 'molecular_formula',
                        type: 'input',
                        placeholder: 'Molecular Formula',
                    }}
                    desc={FieldDescriptions.molecular_formula}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Molecular Mass Isotope"
                    form={form}
                    field={{
                        name: 'molecular_mass_isotope',
                        type: 'input',
                        placeholder: 'Molecular Mass Isotope',
                    }}
                    desc={FieldDescriptions.molecular_mass_isotope}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Alternate CASRNs"
                    form={form}
                    field={{
                        name: 'alternative_casrn_scifinder',
                        type: 'input',
                        placeholder: 'Alternate CASRNs',
                    }}
                    desc={FieldDescriptions.alternative_casrn_scifinder}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Deleted CASRNs"
                    form={form}
                    field={{
                        name: 'deleted_casrn_scifinder',
                        type: 'input',
                        placeholder: 'Deleted CASRNs',
                    }}
                    desc={FieldDescriptions.deleted_casrn_scifinder}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="DTXSID"
                    form={form}
                    field={{
                        name: 'dtxsid',
                        type: 'input',
                        placeholder: 'DTXSID',
                    }}
                    desc={FieldDescriptions.dtxsid}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Chemical Structure"
                    form={form}
                    field={{
                        name: 'chemical_structure',
                        type: 'image',
                    }}
                    desc={FieldDescriptions.chemical_structure}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Verified in Commerce"
                    form={form}
                    field={{
                        name: 'verified_in_commerce',
                        type: 'boolean',
                        options: booleanOptions,
                        placeholder: 'Verified in Commerce',
                    }}
                    desc={FieldDescriptions.verified_in_commerce}
                    isDescShown={isDescShown}
                />
                <FormTableRow
                    label="Synonyms"
                    form={form}
                    field={{
                        name: 'synonyms',
                        type: 'input',
                        placeholder: 'Synonyms',
                    }}
                    desc={FieldDescriptions.synonyms}
                    isDescShown={isDescShown}
                />
            </DefaultTable>

            {isSubstanceTypeShown && (
                <>
                    <GroupHeader>Substance Type</GroupHeader>
                    <DefaultTable>
                        <FormTableRow
                            label="UVCB"
                            form={form}
                            field={{
                                name: 'uvcb',
                                type: 'boolean',
                                options: booleanOptions,
                            }}
                            desc={FieldDescriptions.uvcb}
                        />
                        <FormTableRow
                            label="Organic"
                            form={form}
                            field={{
                                name: 'organic',
                                type: 'boolean',
                                options: booleanOptions,
                            }}
                            desc={FieldDescriptions.organic}
                        />
                        <FormTableRow
                            label="Contains Metal"
                            form={form}
                            field={{
                                name: 'contains_metal',
                                type: 'boolean',
                                options: booleanOptions,
                            }}
                            desc={FieldDescriptions.contains_metal}
                        />
                        <FormTableRow
                            label="Contains Silicon"
                            form={form}
                            field={{
                                name: 'contains_silicon',
                                type: 'boolean',
                                options: booleanOptions,
                            }}
                            desc={FieldDescriptions.contains_silicon}
                            isDescShown={isDescShown}
                        />
                        <FormTableRow
                            label="Contains Phosphorous"
                            form={form}
                            field={{
                                name: 'contains_phosphorous',
                                type: 'boolean',
                                options: booleanOptions,
                            }}
                            desc={FieldDescriptions.contains_phosphorous}
                            isDescShown={isDescShown}
                        />
                        <FormTableRow
                            label="Contains Sulfur"
                            form={form}
                            field={{
                                name: 'contains_sulfur',
                                type: 'boolean',
                                options: booleanOptions,
                            }}
                            desc={FieldDescriptions.contains_sulfur}
                            isDescShown={isDescShown}
                        />
                        <FormTableRow
                            label="Contains Halogen"
                            form={form}
                            field={{
                                name: 'contains_halogen',
                                type: 'boolean',
                                options: booleanOptions,
                            }}
                            desc={FieldDescriptions.contains_halogen}
                            isDescShown={isDescShown}
                        />
                    </DefaultTable>
                </>
            )}

        </div>
    );
};

export default IdentityForm;
