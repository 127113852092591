import React, {FC} from 'react';
import {IChemical, IForm} from "types";
import {ComingSoon, GroupHeader, RiskImg} from "components/pages/ChemicalDetails/parts/Tabs/styled";
import risk from "assets/images/risk.jpg";

type Props = {
    form?: IForm;
    chemical: IChemical | null;
}

const Risk: FC<Props> = ({form, chemical}) => {
    return (
        <div>
            <GroupHeader>Risk</GroupHeader>
            <ComingSoon>Coming Soon!</ComingSoon>
            {/*<RiskImg src={risk} />*/}
        </div>
    );
};

export default Risk;
