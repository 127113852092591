import React, {FC} from 'react';
import {IChemical, IForm} from "types";
import {FormControl} from "components/pages/ChemicalDetails/parts/Tabs/Common";
import {
    ChemPropertiesTable,
    ComingSoon,
    Desc,
    GroupHeader,
    Label,
    TitleCell,
    ValueCell,
    WideTableHeader
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    form: IForm;
    chemical: IChemical | null;
    isDescShown: boolean;
}

const HazardForm: FC<Props> = ({form, chemical, isDescShown}) => {
    return (
        <div>
            <GroupHeader>Environmental Fate and Transport (Predicted)</GroupHeader>
            <ChemPropertiesTable>
                <div/>
                <WideTableHeader>Median</WideTableHeader>
                <WideTableHeader>Low</WideTableHeader>
                <WideTableHeader>High</WideTableHeader>
                <WideTableHeader>Unit</WideTableHeader>

                <TitleCell>
                    <Label>Fish biotransformation Half-Life (km)</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'fish_biotransformation_halflife_avg',
                            type: 'input',
                            label: 'Median',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'fish_biotransformation_halflife_low',
                            type: 'input',
                            label: 'Low',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'fish_biotransformation_halflife_high',
                            type: 'input',
                            label: 'High',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'fish_biotransformation_halflife_unit',
                            type: 'input',
                            label: 'Unit',
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Bioconcentration Factor</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'bioconcentration_factor_avg',
                            type: 'input',
                            label: 'Median',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'bioconcentration_factor_low',
                            type: 'input',
                            label: 'Low',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'bioconcentration_factor_high',
                            type: 'input',
                            label: 'High',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'bioconcentration_factor_unit',
                            type: 'input',
                            label: 'Unit',
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Soil Adsorption Coefficient (Koc)</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'soil_adsorption_coefficient_avg',
                            type: 'input',
                            label: 'Median',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'soil_adsorption_coefficient_low',
                            type: 'input',
                            label: 'Low',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'soil_adsorption_coefficient_high',
                            type: 'input',
                            label: 'High',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'soil_adsorption_coefficient_unit',
                            type: 'input',
                            label: 'Unit',
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Ready Biodegradation</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'ready_biodegredation_avg',
                            type: 'input',
                            label: 'Median',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'ready_biodegredation_low',
                            type: 'input',
                            label: 'Low',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'ready_biodegredation_high',
                            type: 'input',
                            label: 'High',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'ready_biodegredation_unit',
                            type: 'input',
                            label: 'Unit',
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Atmospheric Hydroxylation Rate</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'atmospheric_hydroxylation_rate_avg',
                            type: 'input',
                            label: 'Median',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'atmospheric_hydroxylation_rate_low',
                            type: 'input',
                            label: 'Low',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'atmospheric_hydroxylation_rate_high',
                            type: 'input',
                            label: 'High',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'atmospheric_hydroxylation_rate_unit',
                            type: 'input',
                            label: 'Unit',
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Biodegradation Half-Life</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'biodegregation_halflife_avg',
                            type: 'input',
                            label: 'Median',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'biodegregation_halflife_low',
                            type: 'input',
                            label: 'Low',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'biodegregation_halflife_high',
                            type: 'input',
                            label: 'High',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'biodegregation_halflife_unit',
                            type: 'input',
                            label: 'Unit',
                        }}
                    />
                </ValueCell>

                <TitleCell>
                    <Label>Bioaccumulation Factor</Label>
                    {isDescShown && <Desc></Desc>}
                </TitleCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'bioaccumulation_factor_avg',
                            type: 'input',
                            label: 'Median',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'bioaccumulation_factor_low',
                            type: 'input',
                            label: 'Low',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'bioaccumulation_factor_high',
                            type: 'input',
                            label: 'High',
                        }}
                    />
                </ValueCell>
                <ValueCell>
                    <FormControl
                        form={form}
                        field={{
                            name: 'bioaccumulation_factor_unit',
                            type: 'input',
                            label: 'Unit',
                        }}
                    />
                </ValueCell>

            </ChemPropertiesTable>

            <GroupHeader>Ecotoxicity</GroupHeader>
            <ComingSoon>Coming Soon!</ComingSoon>

            <GroupHeader>Mammalian Toxicity</GroupHeader>
            <ComingSoon>Coming Soon!</ComingSoon>
        </div>
    );
};

export default HazardForm;
