import styled from "styled-components";
import {Input, PhoneInput, SingleSelect} from "components/controls";

export const Title = styled.div`
  margin-bottom: 27px;
  border-bottom: 1px solid #E4E4E4;
`;

export const H2Styled = styled.h2`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: ${(props) => props.theme.fontFamilySecondary};
`;

export const Content = styled.div`
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
`;

export const FormInput = styled(Input)`
  width: 100%;
  max-width: 220px;
  min-width: 150px;
`;

export const FormPhoneInput = styled(PhoneInput)`
  width: 100%;
  max-width: 277px;
  min-width: 150px;
  
  & input {
    width: 100%;
    max-width: 277px;
    min-width: 150px;
  }
`;

export const FormSingleSelect = styled(SingleSelect)`
  width: 100%;
  max-width: 277px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;
