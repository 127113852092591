import React, {FC, ForwardedRef, forwardRef, useEffect, useImperativeHandle} from 'react';
import {useForm} from "react-hook-form";
import {IChemical, ITab} from "types";
import {
    ExposureForm,
    HazardForm,
    IdentityForm,
    Overview,
    PropertiesForm,
    Risk,
    UseForm,
    ClassificationForm,
    RegulationForm
} from "components/pages/ChemicalDetails/parts/Tabs";

type Props = {
    tab: ITab['alias'];
    isDescShown: boolean;
    chemical: IChemical | null;
    handleFormSubmit: (data: any) => void;
    setIsFormUpdated: React.Dispatch<React.SetStateAction<boolean>>;
    ref: ForwardedRef<{handleSave: () => void}>;
}

const FormView: FC<Props> = forwardRef(({tab, isDescShown, chemical, handleFormSubmit, setIsFormUpdated}: Props, ref: ForwardedRef<{handleSave: () => void}>) => {

    const {control, handleSubmit, reset, getValues, formState: {errors, isDirty, dirtyFields }} = useForm({
        mode: 'onBlur',
        defaultValues: {

        }
    });

    useEffect(() => {
        if (chemical) {
            reset({...chemical});
        }
    }, [chemical]);

    useImperativeHandle(ref, () => ({
        handleSave() {
            handleSubmit((data) => handleFormSubmit(data))();
        },
    }));

    useEffect(() => {
        setIsFormUpdated(isDirty);
    }, [isDirty]);

    return (
        <>
            {(tab === 'identity') &&
                <IdentityForm
                    key="IdentityForm"
                    form={{control, errors}}
                    chemical={chemical}
                    isDescShown={isDescShown}
                />
            }
            {(tab === 'properties') &&
                <PropertiesForm
                    form={{control, errors}}
                    chemical={chemical}
                    isDescShown={isDescShown}
                />
            }
            {
                (tab === 'use') &&
                <UseForm
                    form={{control, errors}}
                    chemical={chemical}
                    isDescShown={isDescShown}
                />
            }
            {
                (tab === 'classification') &&
                <ClassificationForm
                    form={{control, errors}}
                    chemical={chemical}
                    isDescShown={isDescShown}
                />
            }
            {
                (tab === 'regulation') &&
                <RegulationForm
                    form={{control, errors}}
                    chemical={chemical}
                    isDescShown={isDescShown}
                />
            }
            {
                (tab === 'hazard') &&
                <HazardForm
                    form={{control, errors}}
                    chemical={chemical}
                    isDescShown={isDescShown}
                />
            }
            {
                (tab === 'exposure') &&
                <ExposureForm
                    form={{control, errors}}
                    chemical={chemical}
                    isDescShown={isDescShown}
                />
            }
            {
                (tab === 'risk') &&
                <Risk
                    form={{control, errors}}
                    chemical={chemical}
                />
            }
            {
                (tab === 'overview') &&
                <Overview
                    chemical={chemical}
                />
            }
        </>
    )
});

FormView.displayName = 'FormView';

export default FormView;
