import styled from "styled-components";
import {DataTable} from "components/common";
import {Button} from "components/controls";

export const Wrapper = styled.div`
  color: ${(props) => props.theme.blackTextColor};
`;

export const Title = styled.div`
  margin-bottom: 27px;
  border-bottom: 1px solid #E4E4E4;
`;

export const H2Styled = styled.h2`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: ${(props) => props.theme.fontFamilySecondary};
`;

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
  font-weight: 700;
  font-size: 26px;
  white-space: nowrap;
  height: 72px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &:first-child {
    @media screen and (max-width: 674px){
      flex-direction: column;
      &>* {
        margin-bottom: 10px;  
      }
      &>*:last-child {
        margin-top: 25px;
      }
    }
  }
`;

export const LabelStyled = styled.label`
  font-weight: 600;
  font-size: 16px;
  margin-right: 26px;
`;

export const UploadButton = styled(Button)`
  margin-left: 16px;
  margin-right: 30px;
  font-size: 12px;
`;

export const TemplateLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  
  &:hover {
    font-weight: 600;
  }
  
  &:visited {
    color: ${(props) => props.theme.blackTextColor};
  }
`;

export const PreviousImport = styled.div`
  margin-top: 80px;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
  text-decoration: underline;
`;

export const Table = styled.div`
  margin-left: -5px;
  margin-top: 34px;
  width: 600px;
`;

export const TableRowHeader = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
`;

export const TableFirstCol = styled.div`
  padding: 5px 5px 5px 5px;
  width: 60%;
`;

export const TableSecondCol = styled.div`
  width: 120px;
  margin-left: 20px;
  padding: 5px 5px 5px 5px;
`;

export const TableRow = styled.div`
  display: flex;
  margin: 10px 0 10px 0;

  ${TableFirstCol}:first-child {
    background: #F3F3F3;
    border-radius: 4px;
  }
`;

export const StyledDataTable = styled(DataTable)`
  //max-width: 835px;
  margin-top: 15px;
`;

export const customDataTableStyles = {
    // headRow: {
    //     style: {
    //         minHeight: '28px',
    //         backgroundColor: '#EDF6F8',
    //         fontSize: '14px',
    //         fontWeight: 700,
    //     },
    // },
    // rows: {
    //     style: {
    //         minHeight: '30px',
    //     },
    // },
};
