import styled from "styled-components";
import {Link} from 'react-router-dom';
import header from 'assets/images/header.jpg';
import {ReactComponent as BurgerMenuIcon} from "assets/images/icons/burger_icon.svg";
import {ReactComponent as MenuCloseImg} from "assets/images/icons/close.svg";

export const StyledHeader = styled.header`
  height: 96px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  //background-image: url(${header});
  //background-repeat: round;
`;

export const Img = styled.img`
  margin-left: 54px;
  
  @media screen and (max-width: 670px){
    margin-left: 15px;
    margin-top: 15px;
    width: 300px;
  }
`;

export const StyledLink:any = styled(Link)<{$isActive?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 48px;
  min-width: 120px;
  height: 32px;
  text-align: center;
  color: ${(props) => props.theme.blackTextColor};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  
  ${(props) => props.$isActive && {
    backgroundColor: props.theme.blue2Color,
    color: '#FFF',
  }}
`;

export const UlStyled = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  list-style-type: none;
  height: 100%;
`;

export const Hamburger = styled(BurgerMenuIcon)`
  margin-right: 25px;
  &:hover {
    cursor: pointer;
    scale: 110%;
  }
`;

export const Menu = styled.div`
  position: absolute;
  padding: 24px 16px 28px 24px;
  width: 290px;
  height: 100vh;
  background-color: #FFF;
  z-index: 1;
  top: 0;
  right: 0;
  border-bottom-left-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.65);
`;

export const MenuHeader = styled.div`
  display: flex;
  padding-bottom: 46px;
  justify-content: space-between;
  align-items: center;
`;

export const MenuCloseImgStyled = styled(MenuCloseImg)`
  &:hover {
    cursor: pointer;
    scale: 110%;
  }
`;

export const MenuH2Styled = styled.h2`
  display: flex;
  padding: 0;
  margin: 0;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

export const PopupMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0;
  margin: 0;
`;

export const PopupMenuItem = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr;
  margin-bottom: 15px;

  &:hover {
    transform:scale(1.02);
    transform-origin:center center;
  }
`;

export const PopupMenuLink = styled(Link)`
  justify-content: center;
  align-items: center;
  min-width: 120px;
  text-align: left;
  margin-left: 8px;
  background-color: #FFF;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    font-weight: 700;
  }
`;
